import React from 'react';
import Styled from 'styled-components/native';
import {dayAndTime} from '@hello10/jump-util';

import {
  Avatar,
  Button,
  ButtonRow,
  ConfirmButton,
  MutationStatus,
  useMutation
} from '@hello10/shared-client';
import {SpaceCover} from '../../Space';
import {
  RespondToInviteMutation
} from '../UserGQL';

const S = {
  Invites: Styled.View``,
  Invite: Styled.View`
    margin-bottom: 32px;
  `,
  Description: Styled.Text`
    margin-top: 8px;
  `,
  Inviter: Styled.View`
    margin-top: 4px;
    flex: 1 0 auto;
    flex-direction: row;
    align-items: center;
  `,
  InvitedBy: Styled.Text``,
  Name: Styled.Text`
    margin-left: 4px;
  `,
  Avatar: Styled(Avatar)`
    margin-left: 4px;
  `,
  InvitedAt: Styled.Text`
    margin-left: 4px;
  `,
  Accept: Styled(Button)``,
  Reject: Styled(ConfirmButton)`
    margin-left: 4px;
  `
};

function Inviter ({invite}) {
  const {inviter, created_at} = invite;
  const {day} = dayAndTime(created_at);
  return (
    <S.Inviter>
      <S.InvitedBy>Invited by</S.InvitedBy>
      <S.Avatar
        user={inviter}
        size={24}
      />
      <S.Name>{inviter.name}</S.Name>
      <S.InvitedAt>on {day}</S.InvitedAt>
    </S.Inviter>
  );
}

function Invite ({invite}) {
  const mutation = useMutation(RespondToInviteMutation);

  function responder (accept) {
    return async function respond () {
      const {id} = invite;
      mutation.setStatus(accept ? 'Accepting' : 'Rejecting');
      await mutation.mutate({
        variables: {id, accept}
      });
      mutation.setStatus('Done');
    };
  }

  const {group} = invite;
  return (
    <S.Invite>
      <SpaceCover
        space={group}
        width={400}
        height={100}
        shape={SpaceCover.Shape.Rectangle}
        edit={false}
      />
      <S.Description>
        {group.description}
      </S.Description>
      <Inviter invite={invite}/>
      <ButtonRow>
        <S.Accept
          label="Accept"
          onPress={responder(true)}
          icon_right="MaterialCommunityIcons.check"
        />
        <S.Reject
          message={`Are you sure you want to reject your invitation to '${group.name}'`}
          label="Reject"
          title="Reject"
          onConfirm={responder(false)}
          icon_right="MaterialIcons.close"
        />
      </ButtonRow>
      <MutationStatus mutation={mutation}/>
    </S.Invite>
  );
}

export default function InvitesTab ({user}) {
  const {invites} = user;
  const $invites = invites.map((invite)=> (
    <Invite
      key={invite.id}
      invite={invite}
    />
  ));

  return (
    <S.Invites>
      {$invites}
    </S.Invites>
  );
}
InvitesTab.label = 'invites';
