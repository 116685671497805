import React, {useState} from 'react';
import Styled from 'styled-components/native';

import {
  Avatar,
  Button,
  ButtonRow,
  CreatedAt,
  DabTextInput,
  Field,
  useDab,
  useMutation,
  UserLink
} from '@hello10/shared-client';
import {
  useSession,
  useRouter
} from '../Application';
import {
  CreateCommentMutation,
  CommentFragment
} from './PostGQL';

const S = {
  Comments: Styled.View``,
  Comment: Styled.View`
    flex: 1 0 auto;
    flex-direction: row;
    margin-bottom: 12px;
  `,
  CommentInput: Styled(DabTextInput)`
    font-size: 14px;
  `,
  Create: Styled.View`
    margin-bottom: 16px;
  `,
  Avatar: Styled(Avatar)`
    width: 24px;
    margin-right: 8px;
  `,
  Content: Styled.View`
    width: 312px;
  `,
  UserLink: Styled(UserLink)``,
  CreatedAt: Styled(CreatedAt)`
    margin-top: 2px;
  `,
  Text: Styled.Text`
    margin-top: 4px;
  `
};

function Comment ({comment}) {
  const router = useRouter();
  const {author} = comment;

  function gotoUser () {
    const {id} = author;
    router.go(`/users/${id}`);
  }

  return (
    <S.Comment>
      <S.Avatar
        user={author}
        size={24}
        onPress={gotoUser}
      />
      <S.Content>
        <S.UserLink
          user={author}
          onPress={gotoUser}
        />
        <S.CreatedAt obj={comment}/>
        <S.Text>{comment.text}</S.Text>
      </S.Content>
    </S.Comment>
  );
}

function CreateComment ({post, space_id, ...props}) {
  const session = useSession();

  const dab = useDab({
    initial: {
      author_id: session.user.id,
      text: null,
      post_id: post.id,
      space_id
    },
    require: ['author_id', 'text', 'post_id', 'space_id']
  });

  const mutation = useMutation({
    mutation: CreateCommentMutation,
    update (cache, {data: {createComment: comment}}) {
      const created = cache.writeFragment({
        data: comment,
        fragment: CommentFragment,
        fragmentName: 'CommentFragment'
      });

      cache.modify({
        id: cache.identify(post),
        fields: {
          comments: (existing)=> [created, ...existing],
          comments_summary: (existing)=> {
            return {
              count: existing.count + 1,
              latest: [
                created, ...existing.latest
              ]
            };
          },
          comments_by_space: (existing, {readField})=> {
            const index = existing.findIndex(({space})=> {
              const existing_sid = readField('id', space);
              const comment_sid = readField('space_id', created);
              return (comment_sid === existing_sid);
            });
            const item = existing[index];
            const new_item = {
              ...item,
              comments: [
                created,
                ...item.comments
              ]
            };
            const before = existing.slice(0, index);
            const after = existing.slice(index + 1);
            return [...before, new_item, ...after];
          }
        }
      });
    }
  });

  async function create () {
    const data = dab.formatted;
    mutation.setStatus('Creating');
    await mutation.mutate({variables: {data}});
    dab.set({text: null});
    mutation.setStatus('Pending');
  }

  return (
    <S.Create {...props}>
      <S.CommentInput
        attr="text"
        placeholder="comment"
        dab={dab}
        multiline={true}
        numberOfLines={4}
      />
      <ButtonRow>
        <Button
          label="Reply"
          size={Button.Size.Small}
          disabled={!dab.is_valid || !mutation.pending}
          onPress={create}
        />
      </ButtonRow>
    </S.Create>
  );
}

export default function Comments ({
  post,
  comments,
  space_id,
  show_create = true,
  ...props
}) {
  const $comments = comments.map((comment)=> (
    <Comment
      comment={comment}
      key={comment.id}
    />
  ));

  const $create = show_create ? (
    <CreateComment
      post={post}
      space_id={space_id}
    />
  ) : null;

  return (
    <S.Comments {...props}>
      {$create}
      {$comments}
    </S.Comments>
  );
}
