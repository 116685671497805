import AsyncStorage from '@react-native-community/async-storage';
import Firebase from 'firebase/app';
import 'firebase/auth';
import {useSingleton} from '@hello10/react-hooks';
import {Session, getGraphQLErrorCode} from '../jump-client';

import SessionUser from './ArcsSessionUser';
import client from './client';

function shouldEndSessionOnError (error) {
  const code = getGraphQLErrorCode(error);
  console.log('hey we got dat error', code, error);
  return [
    'SessionMissing',
    'SessionExpired',
    'SessionUserMissing'
  ].includes(code);
}

const options = {
  Firebase,
  SessionUser,
  client,
  shouldEndSessionOnError,
  storage: AsyncStorage
};

export default function useSession () {
  return useSingleton(Session, options);
}
