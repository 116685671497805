import React, {useState} from 'react';
import Styled from 'styled-components/native';

import {
  ImageChooser,
  ImageChooserPreview
} from '@hello10/shared-client';

const S = {
  Container: Styled.View`
    flex: 1 0 auto;
    flex-direction: row;
    flex-wrap: wrap;
  `,
  Item: Styled.View`
    width: ${({width})=> width}px;
    height: ${({height})=> height}px;
    margin-right: 4px;
    margin-bottom: 4px;
  `
};

const {Square} = ImageChooser.Shape;

export default function ImagesChooser ({
  images,
  setImages,
  shape = Square,
  thumb_size,
  thumb_width,
  thumb_height,
  ...props
}) {
  if (thumb_size && !thumb_width && !thumb_height) {
    thumb_width = thumb_size;
    thumb_height = thumb_size;
    shape = Square;
  }
  const $previews = images.map((image, index)=> (
    <S.Item
      key={index}
      width={thumb_width}
      height={thumb_height}
    >
      <ImageChooserPreview
        media={image}
        shape={shape}
        width={thumb_width}
        onDelete={()=> setImages(images.remove(image))}
      />
    </S.Item>
  ));

  return (
    <S.Container {...props}>
      <S.Item key="New">
        <ImageChooser
          media={null}
          setMedia={(image)=> setImages(images.add(image))}
          width={thumb_width}
          shape={shape}
          prompt="Add image"
        />
      </S.Item>
      {$previews}
    </S.Container>
  );
}

ImagesChooser.Shape = ImageChooser.Shape;
