import React from 'react';
import Styled from 'styled-components/native';

import {
  ImageChooser,
  DabTextInput,
  Field
} from '@hello10/shared-client';

import {MembershipRole} from '../Enums.json';

export {default as SpaceCover} from './SpaceCover';

const S = {
  Description: Styled.Text``,
  Name: Styled.Text`
    margin-top: 8px;
    font-size: 32px;
    font-weight: bold;
  `
};

function hasRole (roles) {
  if (!Array.isArray(roles)) {
    roles = [roles];
  }
  return ({space, user})=> {
    return space.memberships.some((membership)=> {
      const is_user = (membership.member.id === user.id);
      const has_role = roles.includes(membership.role);
      return (is_user && has_role);
    });
  };
}

export const isAdmin = hasRole(MembershipRole.Admin);
export const isMember = hasRole(Object.values(MembershipRole));

export function Name ({space, prefix = '', ...props}) {
  return (
    <S.Name {...props}>
      {prefix}{space.name}
    </S.Name>
  );
}

export function NameField ({dab, ...props}) {
  return (
    <Field
      label="Name"
      {...props}
    >
      <DabTextInput
        attr="name"
        dab={dab}
      />
    </Field>
  );
}

export function CoverChooser ({dab, width, ...props}) {
  const [cover, setCover] = dab.attr('cover');
  return (
    <ImageChooser
      media={cover}
      setMedia={setCover}
      width={width}
      shape={ImageChooser.Shape.None}
      prompt="Add a cover"
      {...props}
    />
  );
}

export function Description ({space, ...props}) {
  const {description} = space;
  if (!description) {
    return null;
  }
  return (
    <S.Description {...props}>
      {description}
    </S.Description>
  );
}
