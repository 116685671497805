import React, {useState} from 'react';
import PropTypes from  'prop-types';
import Styled from 'styled-components/native';

import {
  Button,
  ErrorMessage,
  Field,
  TextInput
} from '@hello10/shared-client';
import {
  Splash,
  useSession
} from '../Application';

const S = {
  Details: Styled.Text`
    text-align: center;
  `,
  Text: Styled.Text`
    display: block;
  `,
  Email: Styled.Text`
    display: block;
    font-weight: bold;
    font-style: italic;
    font-size: 16px;
  `,
  Button: Styled(Button)`
    margin-top: 4px;
  `
};

export default function SigninVerifyPage ({params}) {
  const {email} = params;
  const [code, _setCode] = useState(params.code || '');
  const session = useSession();

  function setCode (code) {
    _setCode(code.trim());
  }

  function startSession () {
    session.start({email, code});
  }

  const {changing, error} = session;

  return (
    <Splash
      header="Enter signin code."
      details={
        <S.Details>
          <S.Text>Enter the code we sent you at</S.Text>
          <S.Email>{email}</S.Email>
        </S.Details>
      }
    >
      <Field label="Signin code">
        <TextInput
          placeholder="Signin code"
          onChangeText={setCode}
          value={code}
        />
      </Field>
      <ErrorMessage error={error} />
      <S.Button
        label="Sign in"
        onPress={startSession}
        size="large"
        disabled={changing}
      />
    </Splash>
  );
}

SigninVerifyPage.propTypes = {
  params: PropTypes.shape({
    email: PropTypes.string.isRequired,
    code: PropTypes.string
  })
};
