import React from 'react';

import {EmailSignin} from '@hello10/shared-client';
import {
  Splash,
  useRouter
} from '../Application';
import {FindOrCreateUserFromEmailMutation} from './UserGQL';

export default function Signin ({
  header,
  label,
  details,
  email,
  invite_id,
  invite_code,
  ...props
}) {
  const router = useRouter();
  return (
    <Splash
      header={header}
      details={details}
      {...props}
    >
      <EmailSignin
        label={label}
        app="Arcs"
        mutation={FindOrCreateUserFromEmailMutation}
        router={router}
        email={email}
        invite_id={invite_id}
        invite_code={invite_code}
      />
    </Splash>
  );
}
