import React from 'react';
import Styled from 'styled-components/native';

import {Avatar} from '@hello10/shared-client';
import {GetUserQuery} from './UserGQL';

const S = {
  User: Styled.View``,
  Avatar: Styled(Avatar)``,
  Username: Styled.Text`
    font-weight: bold;
    font-size: 32px;
  `,
  Name: Styled.Text`
    font-size: 16px;
  `
};

export default function UserPage ({data}) {
  const user = data.getUser;
  return (
    <S.User>
      <S.Avatar
        user={user}
        size={128}
      />
      <S.Username>{user.username}</S.Username>
      <S.Name>{user.name}</S.Name>
    </S.User>
  );
}

UserPage.query = function query ({params}) {
  return {
    query: GetUserQuery,
    variables: {
      id: params.user_id
    }
  };
};
