import React from 'react';
import Styled from 'styled-components/native';

import Post from './Post';
import {GetPostQuery} from './PostGQL';
import * as Space from '../Space';
import {SpaceBanner} from '../Space';

const S = {
  Container: Styled.View``,
  Banner: Styled(SpaceBanner)`
    margin-bottom: 16px;
  `,
};

export default function PostPage ({data, params}) {
  const {getPost: post} = data;

  return (
    <S.Container>
      <S.Banner space={post.space}/>
      <Post
        post={data.getPost}
        width={400}
        space_id={params.space_id}
        show_edit={true}
      />
    </S.Container>
  );
}

PostPage.query = function query ({params}) {
  const {
    space_id,
    post_id
  } = params;
  return {
    query: GetPostQuery,
    variables: {
      id: post_id,
      space_id
    }
  };
};
