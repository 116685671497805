import React, {useState} from 'react';
import Styled from 'styled-components/native';

import {
  useRouter,
  useSession
} from '../Application';
import {
  Button,
  ButtonRow,
  DeleteButton,
  DescriptionField,
  TitleField,
  ImageThumbnailList,
  MutationStatus,
  uploadImage,
  useDab,
  useMutation
} from '@hello10/shared-client';
import {PostType} from '../Enums.json';
import {
  DeletePostMutation,
  GetAuthorPostQuery,
  UpdatePostMutation
} from './PostGQL';
import {Title} from './PostHelpers';
import PostEditor from './PostEditor';

const width = 400;

const S = {
  Container: Styled.View`
    width: ${width}px;
  `,
  DeleteButton: Styled(DeleteButton)`
    margin-right: 4px;
  `,
  Button: Styled(Button)`
    margin-right: 4px;
  `
};

export default function EditPostPage ({data}) {
  const router = useRouter();
  const session = useSession();

  const {getPost: post} = data;

  const dab = useDab({
    initial: {
      title: post.title,
      text: post.text,
      url: post.url,
      piece_ids: post.piece_ids
    },
    require: ['title'],
    validateAll: ({data})=> {
      if (post.type === PostType.Piece) {
        const {piece_ids} = data;
        return (piece_ids.length > 0);
      } else {
        return true;
      }
    }
  });

  const mutation = useMutation(UpdatePostMutation);

  function onDelete ({data, error}) {
    if (data) {
      router.go('/posts');
    } else {
      // TODO: handle this properly
      console.error(error);
    }
  }

  function gotoPost () {
    router.go({
      name: 'Post',
      params: {
        post_id: post.id
      }
    });
  }

  async function update () {
    mutation.setStatus('Updating');
    mutation.mutate({
      variables: {
        id: post.id,
        data: dab.changed
      }
    });
  }

  const delete_mutation = {
    mutation: DeletePostMutation,
    update (cache, {data: {deletePost: deleted}}) {
      if (!deleted) {
        return;
      }

      cache.modify({
        id: `User:${session.user.id}`,
        fields: {
          posts: (existing, {readField})=> {
            return existing.filter((ref)=> {
              const existing_id = readField('id', ref);
              return (post.id !== existing_id);
            });
          }
        }
      });

      // TODO: remove from spaces/posts field
    }
  };

  return (
    <S.Container>
      <Title
        post={post}
        prefix="Edit: "
      />
      <PostEditor
        dab={dab}
        type={post.type}
        width={width}
        mode={PostEditor.Mode.Edit}
      />
      <ButtonRow>
        <S.Button
          label="Post"
          onPress={gotoPost}
          icon_left="MaterialIcons.chevron-left"
        />
        <S.DeleteButton
          message={`Please confirm you want to delete '${post.title}'`}
          mutation={delete_mutation}
          onDelete={onDelete}
          variables={{id: post.id}}
        />
        <S.Button
          label="Update"
          disabled={!dab.is_changed_and_valid}
          onPress={update}
          icon_right="MaterialIcons.done"
        />
      </ButtonRow>
      <MutationStatus mutation={mutation}/>
    </S.Container>
  );
}

EditPostPage.query = function query ({params}) {
  return {
    query: GetAuthorPostQuery,
    variables: {
      id: params.post_id
    }
  };
};
