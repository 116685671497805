import React from 'react';

import {TypeChooser} from '@hello10/shared-client';
import {PollType} from '../Enums.json';

export default function PollTypeChooser (props) {
  const choices = {
    [PollType.Text]: 'MaterialCommunityIcons.text-subject',
    [PollType.Image]: 'FontAwesome.picture-o',
    [PollType.Piece]: 'MaterialCommunityIcons.palette'
  };

  return (
    <TypeChooser
      choices={choices}
      {...props}
    />
  );
}
