import React from 'react';

import Signin from './Signin';

export default function SigninPage () {
  return (
    <Signin
      header="Welcome to Arcs."
      details="A platform for creative collaboration and dialogue within small groups."
      label="SIGN IN"
    />
  );
}
