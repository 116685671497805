import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components/native';

import {
  Button,
  Centered,
  Loading
} from '@hello10/shared-client';
import useSession from './useSession';

const S = {
  Message: Styled.Text`
    margin-top: 8px;
    font-size: 14px;
    opacity: 0.25;
  `
};

function SessionError ({error}) {
  const session = useSession();

  return (
    <>
      <S.Message>
        Error: {error.message}
      </S.Message>
      <Button
        label="Retry"
        onPress={async ()=> {
          await session.end();
        }}
        size="large"
      />
    </>
  );
}

SessionError.propTypes = {
  error: PropTypes.object
};

function SessionLoading () {
  return (
    <Loading />
  );
}

export default function ApplicationLoading ({user, error, ...props}) {
  let $body;
  if (error) {
    $body = (
      <SessionError error={error}/>
    );
  } else {
    $body = (
      <SessionLoading/>
    );
  }

  return (
    <Centered {...props}>
      {$body}
    </Centered>
  );
}

ApplicationLoading.propTypes = {
  user: PropTypes.object,
  error: PropTypes.object
};
