import React, {useState} from 'react';
import Styled from 'styled-components/native';

import {
  Button,
  ButtonRow,
  DescriptionField,
  Field,
  Inviter,
  MutationStatus,
  uploadImage,
  useDab,
  useMutation
} from '@hello10/shared-client';
import {
  useRouter,
  useSession
} from '../Application';
import {FindUser} from '../User';
import {
  CreateSpaceMutation,
  SpaceFragment
} from './SpaceGQL';
import {
  NameField,
  CoverChooser
} from './SpaceHelpers';

const width = 400;

const S = {
  Container: Styled.View`
    width: ${width}px;
  `,
  Text: Styled.Text``
};

export default function CreateSpacePage () {
  const router = useRouter();
  const session = useSession();

  const mutation = useMutation({
    mutation: CreateSpaceMutation,
    update (cache, {data: {createSpace: space}}) {
      const created = cache.writeFragment({
        data: space,
        fragment: SpaceFragment,
        fragmentName: 'SpaceFragment'
      });

      cache.modify({
        id: `User:${session.user.id}`,
        fields: {
          spaces: (existing)=> [created, ...existing],
          dashboard (existing) {
            return {
              ...existing,
              spaces: [created, ...existing.spaces]
            };
          }
        }
      });
    }
  });

  const {id: session_uid} = session.user;

  const dab = useDab({
    initial: {
      invites: [],
      creator_id: session_uid
    },
    require: ['invites', 'name']
  });

  async function create () {
    const {
      cover,
      invites,
      ...data
    } = dab.data;

    data.invites = invites.map(({user, ...data})=> data);

    if (cover) {
      mutation.setStatus('Uploading');
      data.cover = await uploadImage({
        image: cover,
        onProgress: mutation.setProgress
      });
    }

    mutation.setStatus('Creating');
    const result = await mutation.mutate({
      variables: {data}
    });
    const space_id = result?.data?.createSpace?.id;
    if (space_id) {
      router.go({
        name: 'Space',
        params: {space_id}
      });
    }
  }

  return (
    <S.Container>
      <CoverChooser
        dab={dab}
        width={width}
      />
      <NameField dab={dab}/>
      <DescriptionField dab={dab}/>
      <Field label="Invite members">
        <Inviter
          dab={dab}
          inviter_id={session_uid}
          query={FindUser}
        />
      </Field>
      <ButtonRow>
        <Button
          label="Create"
          disabled={!dab.is_valid}
          onPress={create}
          icon_right="MaterialIcons.done"
        />
      </ButtonRow>
      <MutationStatus mutation={mutation}/>
    </S.Container>
  );
}
