import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components/native';

import {
  Button,
  ButtonRow
} from '@hello10/shared-client';
import {useRouter} from '../Application';
import {GetPieceQuery} from './PieceGQL';
import {
  Title,
  Description,
  Url,
  Medium,
  SnapshotCarousel,
  UpdatedAt
} from './PieceHelpers';

import * as PostHelpers from '../Post/PostHelpers';

const {Title: PostTitle} = PostHelpers;

const width = 400;

const S = {
  Buttons: Styled.View`
    margin-top: 8px;
    flex-direction: row;
    justify-content: flex-start;
  `,
  Piece: Styled.View`
    width: ${width}px;
  `,
  Button: Styled(Button)`
    margin-right: 4px;
  `,
  PostLinks: Styled.View`
    margin-top: 16px;
  `,
  PostLinksHeader: Styled.Text`
    font-size: 16px;
    font-weight: bold;
  `
};

function PostLinks ({piece}) {
  const {posts} = piece;
  if (!posts.length) {
    return null;
  }
  const $posts = posts.map((post)=> {
    return (
      <PostTitle
        post={post}
        key={post.id}
      />
    );
  });

  return (
    <S.PostLinks>
      <S.PostLinksHeader>
        Posts you&apos;ve made with this piece
      </S.PostLinksHeader>
      {$posts}
    </S.PostLinks>
  );
}

export default function PiecePage ({data}) {
  const router = useRouter();

  const {getPiece: piece} = data;
  const {id: piece_id} = piece;

  return (
    <S.Piece>
      <SnapshotCarousel
        piece={piece}
        width={width}
      />
      <Title piece={piece}/>
      <Url piece={piece}/>
      <Medium piece={piece}/>
      <UpdatedAt piece={piece}/>
      <Description piece={piece}/>
      <ButtonRow>
        <S.Button
          label="Edit"
          icon_right="MaterialIcons.edit"
          size={Button.Size.Small}
          onPress={()=> {
            router.go({
              name: 'EditPiece',
              params: {piece_id}
            });
          }}
        />
        <S.Button
          label="Post"
          icon_right="MaterialCommunityIcons.comment-outline"
          size={Button.Size.Small}
          onPress={()=> {
            router.go({
              name: 'CreatePost',
              params: {
                piece: piece_id
              }
            });
          }}
        />
      </ButtonRow>
      <PostLinks piece={piece}/>
    </S.Piece>
  );
}

PiecePage.query = function query ({params}) {
  return {
    query: GetPieceQuery,
    variables: {
      id: params.piece_id
    }
  };
};
