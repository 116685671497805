import React from 'react';
import Styled from 'styled-components/native';

import {
  Pre,
  Button,
  ButtonRow,
  MutationStatus,
  Tabs,
  uploadImage,
  useDab,
  useMutation
} from '@hello10/shared-client';
import {useRouter} from '../../Application';
import {
  EditUserQuery,
  UpdateUserMutation
} from '../UserGQL';
import {
  Name
} from '../UserHelpers';
import ProfileTab from './ProfileTab';
import SpacesTab from './SpacesTab';
import InvitesTab from './InvitesTab';

const S = {
  EditUser: Styled.View``
};

export default function EditUserPage ({data}) {
  const router = useRouter();
  const user = data.sessionUser;

  return (
    <S.EditUser>
      <Name user={user}/>
      <Tabs
        tabs={[
          ProfileTab,
          InvitesTab,
          SpacesTab
        ]}
        active_label={router.params.tab}
        onPress={({tab})=> {
          router.go(`/settings/${tab.label}`);
        }}
        tab_props={{user}}
      />
    </S.EditUser>
  );
}

EditUserPage.query = function query () {
  return {
    query: EditUserQuery
  };
};


// import {
//   useTheme,
//   ColorChooser
// } from '@hello10/shared-client';
//
// function ThemeTab () {
//   const theme = useTheme();
//
//   return (
//     <ColorChooser
//       color={theme.color}
//       setColor={theme.setColor}
//     />
//   );
// }

// const [media, setMedia] = React.useState(()=> {
//   let {media = null} = props;
//   if (media) {
//     media = omitTypename(media);
//   }
//   return media;
// });
