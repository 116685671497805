import React from 'react';

import {TypeChooser} from '@hello10/shared-client';
import {PostType} from '../Enums.json';

export default function PostTypeChooser (props) {
  const choices = {
    [PostType.Post]: 'MaterialCommunityIcons.text-subject',
    [PostType.Link]: 'FontAwesome5.link',
    [PostType.Image]: 'FontAwesome.picture-o',
    [PostType.Piece]: 'MaterialCommunityIcons.palette'
    //[PostType.Poll]: 'FontAwesome5.poll'
  };

  return (
    <TypeChooser
      choices={choices}
      {...props}
    />
  );
}
