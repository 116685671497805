import React from 'react';
import {View, TouchableOpacity} from 'react-native';
import {useQuery} from '@apollo/client';
import Styled from 'styled-components/native';
import {bit} from '@hello10/jump-util';

import {PendingQuery} from '@hello10/shared-client';

export default function Chooser ({
  Button,
  attr,
  dab,
  type,
  query,
  numeric,
  ...props
}) {
  type = type.toLowerCase();
  const ptype = `${type}s`;

  if (!attr) {
    attr = `${type}_ids`;
  }

  query = useQuery(query);
  const [ids, setIds] = dab.attr(attr);

  function toggleId (id) {
    setIds(ids.toggle(id));
  }

  if (query.loading || query.error) {
    return (
      <PendingQuery
        query={query}
        loading_text={`Loading ${ptype}`}
        {...props}
      />
    );
  }

  const elements = query.data.sessionUser[ptype];

  const $buttons = elements.map((item)=> {
    const {id} = item;
    const selected = ids.includes(id);
    const index = ids.indexOf(id);
    return (
      <Button
        key={id}
        index={index}
        selected={selected}
        numeric={numeric}
        onPress={()=> {
          toggleId(id);
        }}
        {...{[type]: item}}
      />
    );
  });

  return (
    <View {...props}>
      {$buttons}
    </View>
  );
}
