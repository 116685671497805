import React from 'react';
import Styled from 'styled-components/native';

import PieceList from './PieceList';
import {SessionUserPiecesQuery} from './PieceGQL';

const S = {
  Container: Styled.View``
};

export default function PiecesPage ({data}) {
  const {pieces} = data.sessionUser;
  return (
    <S.Container>
      <PieceList
        pieces={pieces}
        square={false}
      />
    </S.Container>
  );
}

PiecesPage.query = function query () {
  return {
    query: SessionUserPiecesQuery
  };
};
