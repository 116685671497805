import React from 'react';
import Styled from 'styled-components/native';

import {Button} from '@hello10/shared-client';
import {
  useRouter,
  useSession
} from '../Application';
import {Post} from '../Post';
import {GetSpaceQuery} from './SpaceGQL';
import SpaceBanner from './SpaceBanner';

const S = {
  Container: Styled.View``,
  Banner: Styled(SpaceBanner)``,
  Posts: Styled.View``,
  NewButton: Styled(Button)`
    width: 144px;
    margin-bottom: 16px;
  `,
  MoreButton: Styled(Button)`
    width: 144px;
    margin-top: 16px;
    margin-left: 48px;
  `,
  Loading: Styled.Text``
};

function Posts ({
  posts,
  space_id,
  query,
  cursor
}) {
  const $posts = posts.map((post, index)=> (
    <Post
      key={index}
      post={post}
      width={400}
      space_id={space_id}
    />
  ));

  function fetchMore () {
    query.fetchMore({
      variables: {
        after: cursor.end
      }
    });
  }

  let $button = null;
  if (cursor.has_more) {
    $button = (
      <S.MoreButton
        style={{
          marginLeft: 56
        }}
        disabled={query.loading}
        label="More"
        onPress={fetchMore}
        icon_right="Foundation.download"
      />
    );
  }

  let $loading = null;
  if (query.loading) {
    $loading = (
      <S.Loading>Loading...</S.Loading>
    );
  }

  return (
    <S.Posts>
      {$posts}
      {$button}
      {$loading}
    </S.Posts>
  );
}

export default function SpacePage ({data, query}) {
  const router = useRouter();

  const {getSpace: space} = data;
  const {items: posts, cursor} = space.posts;

  return (
    <S.Container>
      <S.Banner space={space}/>
      <S.NewButton
        label="New Post"
        onPress={()=> {
          router.go({
            name: 'CreatePost',
            params: {
              space: space.id
            }
          });
        }}
        icon_left="MaterialCommunityIcons.comment-outline"
      />
      <Posts
        space_id={space.id}
        posts={posts}
        query={query}
        cursor={cursor}
      />
    </S.Container>
  );
}

SpacePage.query = function query ({params}) {
  const {
    space_id: id,
    sort = '-created_at'
  } = params;

  return {
    query: GetSpaceQuery,
    variables: {
      id,
      sort,
      after: null,
      limit: 10,
      comments_limit: 3
    },
    notifyOnNetworkStatusChange: true
  };
};
