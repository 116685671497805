import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components/native';
import {TouchableOpacity} from 'react-native';

import {Icon} from '@hello10/shared-client';

const S = {
  Container: Styled(TouchableOpacity)`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 10;
    right: 8px;
    bottom: 8px;
    width: ${({size})=> size}px;
    height: ${({size})=> size}px;
    border-radius: 50%;
    background-color: black;
  `
};

export default function CreateButton ({toggle, is_open, size}) {
  const icon = is_open ? 'AntDesign.close' : 'AntDesign.plus';

  return (
    <S.Container
      size={size}
      onPress={toggle}
    >
      <Icon
        icon={icon}
        size={size * 0.75}
        color="white"
      />
    </S.Container>
  );
}

CreateButton.propTypes = {
  toggle: PropTypes.func,
  is_open: PropTypes.bool,
  size: PropTypes.number
};
