import React from 'react';
import Styled from 'styled-components/native';

const S = {
  Profile: Styled.View``,
  Derp: Styled.Text``
};

export default function ProfileTab () {
  return (
    <S.Profile>
      <S.Derp>profile!</S.Derp>
    </S.Profile>
  );
}
ProfileTab.label = 'profile';
