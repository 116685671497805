import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components/native';

import {Button} from '@hello10/shared-client';
import useRouter from './useRouter';
import useSession from './useSession';

const S = {
  Menu: Styled.View`
    position: absolute;
    right: 8px;
    top: ${40 + 8}px;
    width: 200px;
    z-index: 10;
    elevation: 10;
  `,
  MenuButton: Styled(Button)`
    margin-bottom: 8px;
  `
};

export default function UserMenu ({close, is_open}) {
  const session = useSession();
  const router = useRouter();

  if (!is_open) {
    return null;
  }

  return (
    <S.Menu>
      <S.MenuButton
        label="Logout"
        onPress={async ()=> {
          close();
          await session.end();
        }}
      />
      <S.MenuButton
        label="Settings"
        onPress={()=> {
          close();
          router.go('/settings');
        }}
      />
    </S.Menu>
  );
}
UserMenu.propTypes = {
  close: PropTypes.func,
  is_open: PropTypes.bool
};
