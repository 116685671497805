import gql from 'graphql-tag';

import {PieceFragment} from '../Application';

export {PieceFragment};

export const CreatePieceMutation = gql`
  ${PieceFragment}
  mutation createPiece ($data: CreatePiece!) {
    createPiece(data: $data) {
      ...PieceFragment
    }
  }
`;

export const UpdatePieceMutation = gql`
  ${PieceFragment}
  mutation updatePiece (
    $id: ObjectID!
    $data: UpdatePiece!
  ) {
    updatePiece(
      id: $id
      data: $data
    ) {
      ...PieceFragment
    }
  }
`;

export const GetPieceQuery = gql`
  ${PieceFragment}
  query getPiece ($id: ObjectID!) {
    getPiece(id: $id) {
      ...PieceFragment
      posts {
        id
        title
      }
    }
  }
`;

export const SessionUserPiecesQuery = gql`
  ${PieceFragment}
  query sessionUserPiecesQuery {
    sessionUser {
      id
      pieces {
        ...PieceFragment
      }
    }
  }
`;

export const DeletePieceMutation = gql`
  mutation deletePiece ($id: ObjectID!) {
    deletePiece(id: $id) {
      id
    }
  }
`;
