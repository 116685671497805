import React from 'react';
import Styled from 'styled-components/native';

import {
  Avatar,
  Button,
  ButtonRow,
  CreatedAt,
  UserLink
} from '@hello10/shared-client';
import {PostType} from '../Enums.json';
import {
  useRouter,
  useSession
} from '../Application';
import {
  Action,
  ImageCarousel,
  PieceCarousel,
  PostLink,
  PostText,
  Title
} from './PostHelpers';
import Poll from './Poll';
import Comments from './Comments';
import AuthorComments from './AuthorComments';
import CommentsSummary from './CommentsSummary';

const S = {
  Post: Styled.View`
    flex: 1 0 auto;
    flex-direction: row;
    margin-bottom: 32px;
  `,
  Avatar: Styled(Avatar)`
    width: 48px;
  `,
  Body: Styled.View`
    margin-left: 8px;
    width: 344px;
  `,
  Header: Styled.View`
    flex: 1 0 auto;
    flex-direction: row;
  `,
  UserLink: Styled(UserLink)``,
  Action: Styled(Action)`
    font-size: 11px;
    margin-left: 4px;
  `,
  PieceCarousel: Styled(PieceCarousel)``,
  ImageCarousel: Styled(ImageCarousel)``,
  Poll: Styled(Poll)``,
  CreatedAt: Styled(CreatedAt)`
    margin-top: 2px;
  `,
  Title: Styled(Title)`
    margin-top: 4px;
    margin-bottom: 8px;
  `,
  Text: Styled(PostText)``,
  URL: Styled(PostLink)``,
  Comments: Styled.View`
    margin-top: 16px;
  `,
  Button: Styled(Button)`
    margin-right: 4px;
  `
};

export default function Post ({
  post,
  width,
  space_id,
  show_edit = false
}) {
  const router = useRouter();
  const session = useSession();

  const {
    author,
    type,
    comments,
    comments_summary,
    comments_by_space
  } = post;

  function gotoUser () {
    const {id} = author;
    router.go(`/users/${id}`);
  }

  const body_width = width - 48 - 8;

  const is_piece = (type === PostType.Piece);
  const is_link = (type === PostType.Link);
  const is_poll = (type === PostType.Poll);
  const is_image = (type === PostType.Image);
  const multi_space = (post.space_ids.length > 1);

  const is_author = (session.user.id === author.id);

  let $comments = null;
  if (is_author && comments_by_space) {
    $comments = (
      <AuthorComments post={post}/>
    );
  } else if (comments) {
    $comments = (
      <Comments
        post={post}
        space_id={space_id}
        comments={comments}
      />
    );
  } else if (comments_summary) {
    $comments = (
      <CommentsSummary
        post={post}
        space_id={space_id}
      />
    );
  }

  return (
    <S.Post>
      <S.Avatar
        user={author}
        size={48}
        onPress={gotoUser}
      />
      <S.Body>
        <S.Header>
          <S.UserLink
            user={author}
            onPress={gotoUser}
          />
          <S.Action post={post}/>
        </S.Header>
        <S.CreatedAt obj={post}/>
        <S.Title
          post={post}
          show_id={false}
          space_id={space_id}
        />
        {is_piece && (
          <S.PieceCarousel
            pieces={post.pieces}
            width={body_width}
          />
        )}
        {is_image && (
          <S.ImageCarousel
            images={post.images}
            width={body_width}
          />
        )}
        {is_poll && (
          <S.Poll
            post={post}
            width={body_width}
          />
        )}
        {is_link && (
          <S.URL post={post}/>
        )}
        <S.Text post={post}/>
        {is_author && show_edit && (
          <ButtonRow>
            <S.Button
              label="Edit"
              icon_right="MaterialIcons.edit"
              size={Button.Size.Small}
              onPress={()=> {
                router.go({
                  name: 'EditPost',
                  params: {
                    post_id: post.id
                  }
                });
              }}
            />
            {space_id && multi_space && (
              <S.Button
                label="All spaces"
                icon_right="MaterialCommunityIcons.vector-square"
                size={Button.Size.Small}
                onPress={()=> {
                  router.go({
                    name: 'Post',
                    params: {
                      post_id: post.id
                    }
                  });
                }}
              />
            )}
          </ButtonRow>
        )}
        <S.Comments>
          {$comments}
        </S.Comments>
      </S.Body>
    </S.Post>
  );
}
