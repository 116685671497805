import {useSingleton} from '@hello10/react-hooks';
import {Router} from '../jump-client';

import redirects from './redirects';
import routes from './routes';

const options = {
  redirects,
  routes,
  onGo: ()=> {
    window.scrollTo(0, 0);
  }
};

export default function useRouter () {
  return useSingleton(Router, options);
}
