import gql from 'graphql-tag';

import {
  PostFragment,
  CommentFragment,
  SpaceFragment
} from '../Application';

export {PostFragment, CommentFragment};

export const CreatePostMutation = gql`
  ${PostFragment}

  mutation createPost ($data: CreatePost!) {
    createPost(data: $data) {
      ...PostFragment
    }
  }
`;

export const UpdatePostMutation = gql`
  ${PostFragment}

  mutation updatePost (
    $id: ObjectID!
    $data: UpdatePost!
  ) {
    updatePost(
      id: $id
      data: $data
    ) {
      ...PostFragment
    }
  }
`;

export const TogglePollVoteMutation = gql`
  ${PostFragment}

  mutation togglePollVote (
    $voter_id: ObjectID!
    $post_id: ObjectID!
    $option_id: ObjectID!
  ) {
    togglePollVote(
      voter_id: $voter_id
      post_id: $post_id
      option_id: $option_id
    ) {
      ...PostFragment
    }
  }
`;

export const GetPostQuery = gql`
  ${CommentFragment}
  ${PostFragment}
  ${SpaceFragment}

  query getPost (
    $id: ObjectID!
    $space_id: ObjectID!
  ) {
    getPost(id: $id) {
      ...PostFragment
      space (space_id: $space_id) {
        ...SpaceFragment
      }
      comments (space_id: $space_id) {
        ...CommentFragment
      }
    }
  }
`;

export const GetAuthorPostQuery = gql`
  ${PostFragment}
  ${CommentFragment}
  ${SpaceFragment}

  query getPostAuthorPostQuery ($id: ObjectID!) {
    getPost(id: $id) {
      ...PostFragment
      comments_by_space {
        space {
          ...SpaceFragment
        }
        comments {
          ...CommentFragment
        }
      }
      spaces {
        id
        name
      }
    }
  }
`;

export const GetPostsQuery = gql`
  query sessionUserPosts {
    sessionUser {
      id
      posts {
        id
        title
      }
    }
  }
`;

export const CreateCommentMutation = gql`
  ${CommentFragment}

  mutation createComment ($data: CreateComment!) {
    createComment(data: $data) {
      ...CommentFragment
    }
  }
`;

export const DeletePostMutation = gql`
  mutation deletePost ($id: ObjectID!) {
    deletePost(id: $id) {
      id
    }
  }
`;
