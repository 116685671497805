import React from 'react';
import {Dimensions, TouchableOpacity} from 'react-native';
import Styled from 'styled-components/native';

const S = {
  Container: Styled(TouchableOpacity)`
    margin-left: -8px;
    margin-bottom: 8px;
  `
};

import {
  useRouter,
  useSession
} from '../Application';
import SpaceCover from './SpaceCover';
import {isAdmin} from './SpaceHelpers';

export default function SpaceBanner ({
  space,
  post_id,
  width,
  edit = true,
  ...props
}) {
  const session = useSession();
  const router = useRouter();

  const {user} = session;
  const is_admin = isAdmin({space, user});

  if (!width) {
    ({width} = Dimensions.get('window'));
  }

  return (
    <S.Container
      onPress={()=> {
        let path = `/spaces/${space.id}`;
        if (post_id) {
          path = `${path}/posts/${post_id}`;
        }
        router.go(path);
      }}
      {...props}
    >
      <SpaceCover
        space={space}
        width={width}
        height={60}
        shape={SpaceCover.Shape.Rectangle}
        edit={edit && is_admin}
      />
    </S.Container>
  );
}
