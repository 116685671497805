import gql from 'graphql-tag';

import {
  PieceFragment,
  PostFragment,
  SpaceFragment
} from '../Application';

export const DashboardQuery = gql`
  ${PieceFragment}
  ${PostFragment}
  ${SpaceFragment}

  query dashboard {
    sessionUser {
      id
      dashboard {
        spaces {
          ...SpaceFragment
        }
        pieces {
          ...PieceFragment
        }
        posts {
          ...PostFragment
        }
      }
    }
  }
`;
