import React from 'react';
import Styled from 'styled-components/native';

import {Splash} from '../Application';

export default function UserSuspendedPage () {
  return (
    <Splash
      header="Account suspended"
    />
  );
}
