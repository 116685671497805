import React from 'react';
import Styled from 'styled-components/native';

import {SessionUserSpacesQuery} from './SpaceGQL';
import SpaceList from './SpaceList';

const S = {
  Container: Styled.View``
};

export default function SpacesPage ({data}) {
  const {spaces} = data.sessionUser;

  return (
    <S.Container>
      <SpaceList spaces={spaces}/>
    </S.Container>
  );
}

SpacesPage.query = function query () {
  return {
    query: SessionUserSpacesQuery
  };
};
