import React from 'react';
import {TouchableOpacity} from 'react-native';
import Styled from 'styled-components/native';

import {useRouter} from '../Application';

const S = {
  DashedBox: Styled(TouchableOpacity)`
    border-radius: 2px;
    padding: 8px;
    border: 1px dashed black;
    width: ${({size})=> size}px;
    height: ${({size})=> size}px;
  `,
  DashedBoxText: Styled.Text`
    font-size: 24px;
    font-weight: bold;
  `
};

export default function DashedBox ({size, text, route, ...props}) {
  const router = useRouter();

  return (
    <S.DashedBox
      onPress={()=> {
        router.go(route);
      }}
      size={size}
      {...props}
    >
      <S.DashedBoxText>
        {text}
      </S.DashedBoxText>
    </S.DashedBox>
  );
}
