import React from 'react';
import {TouchableOpacity} from 'react-native';
import {useQuery} from '@apollo/client';
import Styled from 'styled-components/native';
import {bit} from '@hello10/jump-util';

import {SizedImage, Icon} from '@hello10/shared-client';
import {UserPiecesQuery} from '../User';
import Chooser from './Chooser';

const S = {
  Chooser: Styled(Chooser)`
    flex: 1 0 auto;
    flex-direction: row;
    flex-wrap: wrap;
  `,
  PieceButton: Styled(TouchableOpacity)`
    margin-right: 4px;
    margin-bottom: 4px;
  `,
  PieceSelected: Styled(Icon)`
    position: absolute;
    bottom: 4px;
    right: 4px;
    opacity: ${({selected})=> selected ? 1.0 : 0.25};
    color: white;
    text-shadow-color: ${({selected})=> selected ? '#000000ff' : '#00000000'};
    text-shadow-offset: 0 0;
    text-shadow-radius: 4px;
  `,
  PieceTitle: Styled.Text``
};

function PieceButton ({
  piece,
  numeric,
  selected,
  index,
  ...props
}) {
  let icon;
  if (numeric) {
    let name = 'circle-outline';
    if (selected) {
      let num = index + 1;
      if (num > 9) {
        num = '9-plus';
      }
      name = `numeric-${num}-circle-outline`;
    }
    icon = `MaterialCommunityIcons.${name}`;
  } else {
    icon = `FontAwesome5.${selected ? 'check-circle' : 'circle'}`;
  }

  return (
    <S.PieceButton {...props}>
      <SizedImage
        border={true}
        image={piece.snapshot}
        width={96}
        height={96}
      />
      <S.PieceSelected
        icon={icon}
        size={24}
        selected={bit(selected)}
      />
    </S.PieceButton>
  );
}

export default function PieceChooser (props) {
  return (
    <S.Chooser
      type="Piece"
      query={UserPiecesQuery}
      Button={PieceButton}
      {...props}
    />
  );
}
