import gql from 'graphql-tag';

import {
  CommentFragment,
  ImageFragment,
  InviteFragment,
  PostFragment,
  SpaceFragment,
  MembershipFragment
} from '../Application';

export {SpaceFragment};

export const EditSpaceFragment = gql`
  ${ImageFragment}
  ${MembershipFragment}
  ${InviteFragment}

  fragment EditSpaceFragment on Space {
    id
    name
    description
    cover {
      ...ImageFragment
    }
    memberships {
      ...MembershipFragment
    }
    invites(status: Open) {
      ...InviteFragment
    }
  }
`;

export const GetSpaceQuery = gql`
  ${SpaceFragment}
  ${PostFragment}
  ${CommentFragment}

  query getSpace (
    $id: ObjectID!
    $after: String
    $limit: Int!
    $sort: String!
    $comments_limit: Int!
  ) {
    getSpace(id: $id) {
      ...SpaceFragment
      posts(
        paging: {
          after: $after
          limit: $limit
          sort: $sort
        }
      ){
        items {
          ...PostFragment
          ... on PostInterface {
            comments_summary(
              space_id: $id
              limit: $comments_limit
            ) {
              count
              latest {
                ...CommentFragment
              }
            }
          }
        }
        cursor {
          start
          end
          has_more
          count
          sort
        }
      }
    }
  }
`;

export const SessionUserSpacesQuery = gql`
  ${SpaceFragment}
  query sessionUserSpaces {
    sessionUser {
      id
      spaces {
        ...SpaceFragment
      }
    }
  }
`;

export const GetSpaceForEditQuery = gql`
  ${EditSpaceFragment}
  query getSpaceForEdit ($id: ObjectID!) {
    getSpace(id: $id) {
      ...EditSpaceFragment
    }
  }
`;

export const CreateSpaceMutation = gql`
  ${SpaceFragment}
  mutation createSpace ($data: CreateSpace!) {
    createSpace(data: $data) {
      ...SpaceFragment
    }
  }
`;

export const UpdateSpaceMutation = gql`
  ${EditSpaceFragment}
  mutation updateSpace (
    $id: ObjectID!
    $data: UpdateSpace!
  ) {
    updateSpace(
      id: $id
      data: $data
    ) {
      ...EditSpaceFragment
    }
  }
`;
