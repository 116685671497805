import React from 'react';
import Styled from 'styled-components/native';
import {ScrollView} from 'react-native-gesture-handler';

import {
  Avatar,
  Hello10Copyright,
  useToggle
} from '@hello10/shared-client';
import useSession from './useSession';
import UserMenu from './UserMenu';
import CreateMenu from './CreateMenu';
import CreateButton from './CreateButton';
import Header from './Header';

const S = {
  Container: Styled.View`
    width: 100%;
    height: 100%;
  `,
  Splash: Styled.View`
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 24px;
  `,
  Body: Styled(ScrollView)`
    width: 100%;
    height: 100%;
    padding: 0 8px 32px 8px;
    z-index: 1;
  `,
  Header: Styled(Header)`
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 10;
  `,
  Avatar: Styled(Avatar)`
    position: fixed;
    right: 8px;
    top: 8px;
    z-index: 10;
  `,
  Text: Styled.Text``,
  Copyright: Styled(Hello10Copyright)`
    position: absolute;
    left: 4px;
    bottom: 4px;
    z-index: 10;
  `
};

// Have to keep all these items as siblings so that z-index works on RN :|
export default function Container ({match, children}) {
  const {user} = useSession();

  const user_menu = useToggle(false);
  const create_menu = useToggle(false);

  if (match.route.splash) {
    return (
      <S.Splash>
        {children}
      </S.Splash>
    );
  }

  let $avatar;
  if (user.isSignedIn()) {
    $avatar = (
      <S.Avatar
        user={user}
        size={32}
        onPress={user_menu.toggle}
      />
    );
  }

  return (
    <S.Container>
      <S.Header />
      {$avatar}
      <UserMenu {...user_menu} />
      <S.Body>
        {children}
      </S.Body>
      <CreateMenu {...create_menu} />
      <CreateButton
        {...create_menu}
        size={52}
      />
      <S.Copyright
        font_size={10}
        logo_size={24}
      />
    </S.Container>
  );
}
