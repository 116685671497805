import React from 'react';

import {Splash as SharedSplash} from '@hello10/shared-client';
import HomeButton from './HomeButton';

export default function Splash (props) {
  return (
    <SharedSplash
      Logo={HomeButton}
      {...props}
    />
  );
}
