import deepEqual from 'deep-equal';

import {getClient} from '../jump-client';

import {config} from '@hello10/shared-client';

const post_types = [
  'Post',
  'ImagePost',
  'LinkPost',
  'PiecePost',
  'PollPost'
];

function paginatedFieldPolicy () {
  function empty () {
    return {
      cursor: {
        start: null,
        end: null,
        sort: null,
        has_more: true,
        count: 0
      },
      items: []
    };
  }

  return {
    merge (current = empty(), next, {args}) {
      const cursor = {...current.cursor};

      // TODO: handle reversing without refetch?
      if (cursor.sort !== args.paging.sort) {
        return next;
      }

      // FUCKING APOLLO YOU FUCK
      if (current.replace) {
        return {
          cursor: current.cursor,
          items: current.items
        };
      }

      const {end, has_more, count} = next.cursor;
      cursor.has_more = has_more;
      cursor.count += count;
      if (end) {
        cursor.end = end;
      }

      const items = [
        ...current.items,
        ...next.items
      ];

      return {
        cursor,
        items
      };
    },
    read (current = empty()) {
      return current;
    }
  };
}

const client = getClient({
  uri: `${config.backend_uri}/graphql`,
  cache_options: {
    possibleTypes: {
      PostInterface: post_types,
      Paged: [...post_types],
      PollOption: ['PollText', 'Piece'],
      MediaSubject: ['User', 'Piece', 'ImagePost'],
      Group: ['Space'],
      Media: ['Image']
    },
    typePolicies: {
      Space: {
        fields: {
          posts: paginatedFieldPolicy()
        }
      }
    }
  }
  // defaultOptions: {
  //   // watchQuery: {
  //   //   fetchPolicy: 'no-cache',
  //   //   errorPolicy: 'ignore'
  //   // },
  //   query: {
  //     fetchPolicy: 'cache-and-network',
  //     nextFetchPolicy: 'cache-first',
  //     errorPolicy: 'all',
  //     notifyOnNetworkStatusChange: true
  //   }
  // },
});

export default client;
