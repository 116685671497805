import React, {useState} from 'react';
import Styled from 'styled-components/native';

import {
  Button,
  ButtonRow,
  DeleteButton,
  DescriptionField,
  TitleField,
  ImageThumbnailList,
  MutationStatus,
  uploadImage,
  useDab,
  useMutation
} from '@hello10/shared-client';
import {
  useRouter,
  useSession
} from '../Application';
import {
  DeletePieceMutation,
  GetPieceQuery,
  UpdatePieceMutation
} from './PieceGQL';
import {
  Title,
  MediumField,
  SnapshotChooser,
  UrlField
} from './PieceHelpers';

const width = 400;

const S = {
  Container: Styled.View`
    width: ${width}px;
  `,
  DeleteButton: Styled(DeleteButton)`
    margin: 0 4px 0 0;
  `,
  Button: Styled(Button)`
    margin: 0 4px 0 0;
  `
};

export default function EditPiecePage ({data}) {
  const router = useRouter();
  const session = useSession();

  const {
    id: piece_id,
    snapshot, // we ignore this so it doesn't collide with SnapshotChooser
    ...piece
  } = data.getPiece;

  const initial = {
    ...piece,
    deleted_snapshots: []
  };
  const dab = useDab({
    initial,
    require: ['title', 'medium']
  });

  const mutation = useMutation(UpdatePieceMutation);

  function onDelete ({data, error}) {
    if (data) {
      router.go('Pieces');
    } else {
      // TODO: handle this properly
      console.error(error);
    }
  }

  function gotoPiece () {
    router.go({
      name: 'Piece',
      params: {piece_id}
    });
  }

  async function update () {
    const {
      description,
      url,
      snapshot,
      deleted_snapshots
    } = dab.data;

    // 1. check for updated fields
    // Always allow description and url to be deleted
    // Medium and title have to be truthy to be included
    const data = {description, url};
    for (const attr of ['medium', 'title']) {
      const value = dab.data[attr];
      if (value) {
        data[attr] = value;
      }
    }

    // 2. check for deleted snapshots
    if (deleted_snapshots.length) {
      data.delete_snapshot_ids = deleted_snapshots.map((snap)=> snap.id);
    }

    // 3. check for new snapshot
    if (snapshot) {
      mutation.setStatus('Uploading');
      data.snapshot = await uploadImage({
        image: snapshot,
        onProgress: mutation.setProgress
      });
    }

    // 4. update piece
    mutation.setStatus('Updating');
    mutation.mutate({
      variables: {
        id: piece_id,
        data
      }
    });
  }

  const delete_mutation = {
    mutation: DeletePieceMutation,
    update (cache, {data: {deletePost: deleted}}) {
      if (!deleted) {
        return;
      }

      cache.modify({
        id: `User:${session.user.id}`,
        fields: {
          pieces: (existing, {readField})=> {
            return existing.filter((ref)=> {
              const existing_id = readField('id', ref);
              return (piece.id !== existing_id);
            });
          }
        }
      });
    }
  };

  return (
    <S.Container>
      <Title
        piece={piece}
        prefix="Edit: "
      />
      <SnapshotChooser
        dab={dab}
        width={width}
      />
      <ImageThumbnailList
        dab={dab}
        field="snapshots"
      />
      <TitleField dab={dab}/>
      <MediumField dab={dab}/>
      <UrlField dab={dab}/>
      <DescriptionField dab={dab}/>
      <ButtonRow>
        <S.Button
          label="Piece"
          onPress={gotoPiece}
          icon_left="MaterialIcons.chevron-left"
        />
        <S.DeleteButton
          message={`Please confirm you want to delete '${piece.title}'`}
          mutation={delete_mutation}
          onDelete={onDelete}
          variables={{id: piece_id}}
        />
        <S.Button
          label="Update"
          disabled={!dab.is_changed_and_valid}
          onPress={update}
          icon_right="MaterialIcons.done"
        />
      </ButtonRow>
      <MutationStatus mutation={mutation}/>
    </S.Container>
  );
}

EditPiecePage.query = function query ({params}) {
  return {
    query: GetPieceQuery,
    variables: {
      id: params.piece_id
    }
  };
};
