import React from 'react';
import Styled from 'styled-components/native';

import {Centered} from '@hello10/shared-client';
import {HomeButton} from '../Application';

const Styles = {
  BigText: Styled.Text`
    margin-top: 4px;
    margin-bottom: 16px;
    font-size: 40px;
  `
};

export default function NotFoundPage () {
  return (
    <Centered>
      <HomeButton size={60}/>
      <Styles.BigText>
        Not Found.
      </Styles.BigText>
    </Centered>
  );
}
