import gql from 'graphql-tag';

import {
  ImageFragment,
  UserFragment
} from '../Application';

const GroupFragment = gql`
  ${ImageFragment}

  fragment GroupFragment on Group {
    ... on Space {
      id
      name
      description
      cover {
        ...ImageFragment
      }
    }
  }
`;

const MembershipFragment = gql`
  ${ImageFragment}
  ${GroupFragment}

  fragment MembershipFragment on Membership {
    id
    role
    group {
      ...GroupFragment
    }
    last_active_at
  }
`;

export const FindOrCreateUserFromEmailMutation = gql`
  ${UserFragment}

  mutation findOrCreateUserFromEmail ($data: FindOrCreateUserFromEmail!) {
    findOrCreateUserFromEmail (data: $data) {
      ...UserFragment
    }
  }
`;

export const FindUser = gql`
  ${UserFragment}

  query findUser (
    $string: String
    $email: Email
    $username: Username
  ) {
    findUser(
      string: $string
      email: $email
      username: $username
    ) {
      ...UserFragment
    }
  }
`;

export const ActivateUserMutation = gql`
  ${UserFragment}

  mutation activateUser (
    $id: ObjectID!
    $data: ActivateUser!
  ) {
    activateUser(
      id: $id
      data: $data
    ) {
      ...UserFragment
    }
  }
`;

export const UserSpacesQuery = gql`
  query userSpaces {
    sessionUser {
      id
      spaces {
        id
        name
      }
    }
  }
`;

export const UserPiecesQuery = gql`
  ${ImageFragment}

  query userPieces {
    sessionUser {
      id
      pieces {
        id
        title
        snapshot {
          ...ImageFragment
        }
      }
    }
  }
`;

export const GetUserQuery = gql`
  ${UserFragment}

  query getUser ($id: ObjectID!) {
    getUser (id: $id) {
      ...UserFragment
    }
  }
`;

export const InviteFragment = gql`
  ${ImageFragment}
  ${UserFragment}
  ${GroupFragment}

  fragment InviteFragment on Invite {
    id
    status
    app
    type
    email
    phone
    invitee_id
    inviter {
      ...UserFragment
    }
    group {
      ...GroupFragment
    }
    created_at
  }
`;

export const EditUserQuery = gql`
  ${UserFragment}
  ${InviteFragment}
  ${MembershipFragment}

  query userInvites {
    sessionUser {
      ...UserFragment
      memberships {
        ...MembershipFragment
      }
      invites (
        status: Open
        group_type: Space
      ) {
        ...InviteFragment
      }
    }
  }
`;

export const RespondToInviteMutation = gql`
  ${InviteFragment}

  mutation respondToInvite (
    $id: ObjectID!
    $accept: Boolean!
  ) {
    respondToInvite(
      id: $id
      accept: $accept
    ) {
      ...InviteFragment
    }
  }
`;

export const GetInviteQuery = gql`
  ${InviteFragment}

  query getInvite ($id: ObjectID!) {
    getInvite (id: $id) {
      ...InviteFragment
    }
  }
`;
