import React from 'react';
import {View} from 'react-native';
import Svg, {G, Circle, Path} from 'react-native-svg';

import {useTheme} from '@hello10/shared-client';

export default function Logo ({size}) {
  const theme = useTheme();

  const fill = theme.black100;

  return (
    <View
      style={{
        width: size,
        height: size
      }}
    >
      <Svg
        width={size}
        height={size}
        viewBox="0 0 651 651"
      >
        <G
          id="Arcs-Logo"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <G id="Artboard">
            <Circle
              id="Oval"
              fill={fill}
              cx="325.5"
              cy="325.5"
              r="325.5"
            />
            <Path
              d="M166,455 L326.821429,152 C337.384169,279.451884 352.991312,360.794166 373.642857,396.026846 C394.294403,431.259525 439.080117,450.917243 508,455"
              id="Path"
              stroke="#FFFFFF"
              strokeWidth="48"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </G>
        </G>
      </Svg>
    </View>
  );
}
