import React, {useState} from 'react';
import Styled from 'styled-components/native';

import {
  Button,
  ButtonRow,
  DescriptionField,
  MutationStatus,
  TitleField,
  uploadImage,
  useDab,
  useMutation
} from '@hello10/shared-client';
import {
  useRouter,
  useSession
} from '../Application';
import {
  CreatePieceMutation,
  PieceFragment
} from './PieceGQL';
import {
  MediumField,
  SnapshotChooser,
  UrlField
} from './PieceHelpers';

const width = 400;

const S = {
  Container: Styled.View`
    width: ${width}px;
  `
};

export default function CreatePiecePage () {
  const router = useRouter();
  const session = useSession();

  const mutation = useMutation({
    mutation: CreatePieceMutation,
    update (cache, {data: {createPiece: piece}}) {
      const created = cache.writeFragment({
        data: piece,
        fragment: PieceFragment,
        fragmentName: 'PieceFragment'
      });

      cache.modify({
        id: `User:${session.user.id}`,
        fields: {
          pieces: (existing)=> [created, ...existing],
          dashboard (existing) {
            return {
              ...existing,
              pieces: [created, ...existing.pieces]
            };
          }
        }
      });
    }
  });

  const dab = useDab({
    require: ['snapshot', 'title', 'medium'],
    formatAll: ({data})=> {
      if (!data.url) {
        delete data.url;
      }
      return data;
    }
  });

  async function create () {
    const {
      snapshot,
      ...data
    } = dab.data;

    // 1. Upload snapshot
    mutation.setStatus('Uploading');
    data.snapshot = await uploadImage({
      image: snapshot,
      onProgress: mutation.setProgress
    });

    data.creator_id = session.user.id;

    // 2. run createPiece mutation
    mutation.setStatus('Creating');
    const result = await mutation.mutate({
      variables: {data}
    });
    const piece_id = result?.data?.createPiece?.id;
    if (piece_id) {
      router.go({
        name: 'Piece',
        params: {piece_id}
      });
    }
  }

  return (
    <S.Container>
      <SnapshotChooser
        dab={dab}
        width={width}
      />
      <TitleField dab={dab}/>
      <MediumField dab={dab}/>
      <UrlField dab={dab}/>
      <DescriptionField dab={dab}/>
      <ButtonRow>
        <Button
          label="Create"
          disabled={!dab.is_changed_and_valid || !mutation.pending}
          onPress={create}
          icon_right="MaterialIcons.done"
        />
      </ButtonRow>
      <MutationStatus mutation={mutation}/>
    </S.Container>
  );
}
