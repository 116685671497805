import React, {useState, useEffect} from 'react';
import Styled from 'styled-components/native';
import {buildEnum} from '@hello10/jump-util';
import {useQuery} from '@apollo/client';
import {omit, pick} from 'lodash';

import {
  Button,
  ButtonRow,
  DescriptionField,
  Field,
  Inviter,
  MembershipList,
  MutationStatus,
  PendingQuery,
  Tabs,
  uploadImage,
  useDab,
  useMutation
} from '@hello10/shared-client';
import {
  useRouter,
  useSession
} from '../Application';
import {FindUser} from '../User';
import {
  CoverChooser,
  isAdmin,
  NameField
} from './SpaceHelpers';
import SpaceBanner from './SpaceBanner';
import {
  GetSpaceForEditQuery,
  UpdateSpaceMutation
} from './SpaceGQL';

const width = 400;

const S = {
  EditSpace: Styled.View`
    width: ${width}px;
  `,
  DetailsTab: Styled.View``,
  MembersTab: Styled.View``,
  Text: Styled.Text``,
  Button: Styled(Button)`
    margin: 0 4px 0 0;
  `
};

function DetailsTab ({dab}) {
  return (
    <S.DetailsTab>
      <CoverChooser
        dab={dab}
        width={width}
      />
      <NameField dab={dab}/>
      <DescriptionField dab={dab}/>
    </S.DetailsTab>
  );
}
DetailsTab.label = 'details';

function MembersTab ({dab}) {
  const session = useSession();

  return (
    <S.MembersTab>
      <Field label="Members">
        <MembershipList dab={dab}/>
      </Field>
      <Field label="Invite">
        <Inviter
          dab={dab}
          inviter_id={session.user.id}
          query={FindUser}
        />
      </Field>
    </S.MembersTab>
  );
}
MembersTab.label = 'members';

export default function EditSpacePage ({query}) {
  const router = useRouter();
  const session = useSession();
  const {user} = session;

  const mutation = useMutation(UpdateSpaceMutation);
  const {
    id,
    ...space
  } = query.data.getSpace;

  if (!isAdmin({space, user})) {
    router.go({
      name: 'Space',
      params: {
        space_id: id
      }
    });
  }

  const dab = useDab({
    initial: space,
    require: ['name']
  });

  useEffect(()=> {
    dab.reset(space);
  }, [query.data]);

  async function update () {
    const data = omit(dab.changed, ['cover', 'invites', 'memberships']);

    if (dab.hasChanged('cover')) {
      const {cover} = dab.changed;
      if (cover) {
        mutation.setStatus('Uploading');
        data.cover = await uploadImage({
          image: cover,
          onProgress: mutation.setProgress
        });
      } else {
        data.cover = null;
      }
    }

    if (dab.hasChanged('invites')) {
      const invites_diff = dab.diff('invites');
      data.invite_creates = invites_diff.add.map(({user, ...invite})=> invite);
      data.invite_deletes = invites_diff.remove.map((i)=> i.id);
    }

    if (dab.hasChanged('memberships')) {
      const memberships_diff = dab.diff('memberships');
      data.membership_updates = memberships_diff.add.map((m)=> {
        return pick(m, ['id', 'role', 'status']);
      });
    }

    mutation.setStatus('Updating');
    mutation.mutate({
      variables: {id, data}
    });
  }

  return (
    <S.EditSpace>
      <SpaceBanner
        space={space}
        edit={false}
      />
      <Tabs
        tabs={[
          DetailsTab,
          MembersTab
        ]}
        active_label={router.params.tab}
        onPress={({tab})=> {
          router.go(`/spaces/${id}/edit/${tab.label}`);
        }}
        tab_props={{dab}}
      />
      <ButtonRow>
        <S.Button
          label="Space"
          onPress={()=> {
            router.go(`/spaces/${id}`);
          }}
          icon_left="MaterialIcons.chevron-left"
        />
        <S.Button
          label="Update"
          disabled={!dab.is_changed_and_valid}
          onPress={update}
          icon_right="MaterialIcons.done"
        />
      </ButtonRow>
      <MutationStatus mutation={mutation}/>
    </S.EditSpace>
  );
}

EditSpacePage.query = function query ({params}) {
  return {
    query: GetSpaceForEditQuery,
    variables: {
      id: params.space_id
    }
  };
};
