import React, {useState} from 'react';

import {
  DabTextInput,
  ImageChooser,
  MutationStatus,
  Steps,
  uploadImage,
  useMutation
} from '@hello10/shared-client';
import {
  Splash,
  useSession
} from '../Application';
import {ActivateUserMutation} from './UserGQL';

const size = 240;

function SetupName ({dab}) {
  const $inputs = ['first_name', 'last_name'].map((attr)=> (
    <DabTextInput
      key={attr}
      attr={attr}
      dab={dab}
      style={{
        marginBottom: 4
      }}
    />
  ));
  return (
    <>
      {$inputs}
    </>
  );
}
SetupName.title = 'Name';
SetupName.propTypes = Steps.StepPropTypes;

function SetupAvatar ({attr}) {
  const [media, setMedia] = attr('media');

  return (
    <ImageChooser
      media={media}
      setMedia={setMedia}
      width={size}
      shape={ImageChooser.Shape.Circle}
      prompt="Choose a profile photo"
    />
  );
}
SetupAvatar.title = 'Photo';
SetupAvatar.propTypes = Steps.StepPropTypes;

function SetupUsername ({dab}) {
  return (
    <DabTextInput
      attr="username"
      dab={dab}
    />
  );
}
SetupUsername.title = 'Username';
SetupUsername.propTypes = Steps.StepPropTypes;

export default function UserSetupPage () {
  const session = useSession();

  const mutation = useMutation(ActivateUserMutation);

  const steps = [
    SetupName,
    SetupAvatar,
    SetupUsername
  ];

  async function onDone ({data}) {
    const {
      first_name,
      last_name,
      username,
      media
    } = data;

    // TODO: should get passed so can use in other apps
    const app = 'Arcs';
    const name = [first_name, last_name].join(' ');
    const variables = {
      id: session.user.id,
      data: {name, username, app}
    };

    // 1. upload the image
    if (media) {
      mutation.setStatus('UploadingPhoto');

      variables.data.avatar = await uploadImage({
        image: media,
        onProgress: mutation.setProgress
      });
    }

    // 2. run activateUser mutation
    mutation.setStatus('Activating');
    await mutation.mutate({variables});
    await session.refresh();
  }

  return (
    <Splash
      header="Setup account"
    >
      <Steps
        steps={steps}
        step_height={size}
        onDonePress={onDone}
        doneEnabled={(dab)=> {
          const has_data = dab.hasData([
            'username', 'first_name', 'last_name'
          ]);
          return (has_data && !mutation.loading);
        }}
      />
      <MutationStatus mutation={mutation}/>
    </Splash>
  );
}
