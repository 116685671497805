import React from 'react';
import {
  Text,
  View,
  TouchableOpacity
} from 'react-native';
import Styled from 'styled-components/native';
import {
  dayAndTime,
  pluralize
} from '@hello10/jump-util';

import {
  Button,
  DabTextInput,
  Field,
  onPressRouteOrUrl,
  SizedImageCarousel
} from '@hello10/shared-client';
import {ImagesChooser as BaseImagesChooser} from '../Shared';
import {
  PostType,
  PollType
} from '../Enums.json';
import {Link} from '../Application';
import PieceChooser from './PieceChooser';

const S = {
  Title: Styled.Text`
    font-size: 24px;
    font-weight: bold;
  `,
  Text: Styled.Text`
    font-size: 16px;
  `,
  PostLink: Styled.View``,
  PostLinkText: Styled.Text`
    font-size: 14px;
  `,
  PostLinkButton: Styled(Button)`
    width: 80px;
    margin-top: 4px;
  `,
  ImagesChooser: Styled(BaseImagesChooser)`
    width: ${({width})=> width}px;
  `
};

function nounCount (noun) {
  return (items)=> {
    return pluralize({
      count: items.length,
      singular: noun
    });
  };
}

const pieceCount = nounCount('piece');
const imageCount = nounCount('image');

export function PostTextEditor ({dab, ...props}) {
  return (
    <Field
      label="Text"
      {...props}
    >
      <DabTextInput
        attr="text"
        dab={dab}
        multiline={true}
        numberOfLines={4}
      />
    </Field>
  );
}

export function PieceField ({dab, ...props}) {
  return (
    <Field
      label="Pieces"
      {...props}
    >
      <PieceChooser dab={dab}/>
    </Field>
  );
}

export function UrlField ({dab, ...props}) {
  return (
    <Field
      label="Url"
      {...props}
    >
      <DabTextInput
        attr="url"
        dab={dab}
      />
    </Field>
  );
}

export function Action ({post, ...props}) {
  const {
    type,
    poll,
    images,
    pieces,
    is_question
  } = post;

  let text;
  if (type === PostType.Piece) {
    const prefix = is_question ? 'asked for feedback on' : 'shared';
    const suffix = pieceCount(pieces);
    text = `${prefix} ${suffix}`;
  } else if (type === PostType.Image) {
    const prefix = is_question ? 'asked about' : 'shared';
    const suffix = imageCount(images);
    text = `${prefix} ${suffix}`;
  } else if (type === PostType.Link) {
    text = 'shared a link';
  } else if (type === PostType.Poll) {
    text = 'started a poll';
    if (poll.type === PollType.Piece) {
      const suffix = pieceCount(poll.options);
      text = `${text} for ${suffix}`;
    }
  } else if (type === PostType.Post) {
    text = is_question ? 'asked a question' : 'made a post';
  } else {
    throw new Error(`Invalid post type: ${type}`);
  }

  return (
    <Text {...props}>
      {text}
    </Text>
  );
}

export function Title ({
  post,
  show_id = false,
  prefix = '',
  space_id,
  ...props
}) {
  if (!post) {
    return null;
  }

  const {
    title,
    id: post_id
  } = post;

  let content = title;
  if (show_id) {
    content = `${content} (${post_id})`;
  }

  const route = space_id ? (
    {
      name: 'SpacePost',
      params: {
        post_id,
        space_id
      }
    }
  ) : (
    {
      name: 'Post',
      params: {post_id}
    }
  );

  return (
    <Link
      route={route}
      {...props}
    >
      <S.Title>{prefix}{content}</S.Title>
    </Link>
  );
}

export function PostLink ({post, ...props}) {
  if (!post?.url) {
    return null;
  }
  const {url} = post;
  return (
    <S.PostLink {...props}>
      <S.PostLinkText>{url}</S.PostLinkText>
      <S.PostLinkButton
        label="Open"
        outlined={true}
        size={Button.Size.Small}
        onPress={onPressRouteOrUrl({url})}
        icon_right="MaterialIcons.open-in-new"
      />
    </S.PostLink>
  );
}

export function PostText ({post, ...props}) {
  const {text} = post;
  if (!text?.length) {
    return null;
  }
  return (
    <S.Text {...props}>
      {text}
    </S.Text>
  );
}

export function PieceCarousel ({pieces, ...props}) {
  if (!pieces?.length) {
    return null;
  }
  const images = pieces.map((piece)=> piece.snapshot);
  return (
    <ImageCarousel
      images={images}
      {...props}
    />
  );
}

export function ImageCarousel ({images, ...props}) {
  if (!images?.length) {
    return null;
  }
  return (
    <SizedImageCarousel
      images={images}
      {...props}
    />
  );
}

export function ImagesChooser ({
  dab,
  attr,
  width,
  thumb_size,
  ...props
}) {
  const [images, setImages] = dab.attr(attr);
  return (
    <Field
      label="Images"
      {...props}
    >
      <S.ImagesChooser
        images={images}
        setImages={setImages}
        width={width}
        thumb_size={thumb_size}
      />
    </Field>
  );
}

export function SpaceLinks ({post, ...props}) {
  const {spaces} = post;
  if (!spaces) {
    return null;
  }
  const $spaces = spaces.map(({id, name})=> (
    <Link
      key={id}
      route={{
        name: 'Space',
        params: {id}
      }}
      text={name}
    />
  ));
  return (
    <View {...props}>
      <Text>Shared to:</Text>
      {$spaces}
    </View>
  );
}
