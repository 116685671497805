import React from 'react';
import Styled from 'styled-components/native';

import Post from './Post';
import {GetAuthorPostQuery} from './PostGQL';

const S = {
  Container: Styled.View``,
  Derp: Styled.Text``
};

export default function PostAuthorPage ({data}) {
  return (
    <S.Container>
      <Post
        post={data.getPost}
        width={400}
        show_edit={true}
      />
    </S.Container>
  );
}

PostAuthorPage.query = function query ({params}) {
  return {
    query: GetAuthorPostQuery,
    variables: {
      id: params.post_id
    }
  };
};
