import gql from 'graphql-tag';

import Enums from '../Enums.json';

const {
  UserStatus,
  UserRole
} = Enums;

export default class SessionUser {
  constructor (data = null) {
    this.data = data;
  }

  get client () {
    const {client} = this.constructor;
    if (!client) {
      throw new Error('No client set on SessionUser');
    }
    return client;
  }

  static async load () {
    const query = gql`
      ${this.UserFragment}
      {
        sessionUser {
          ...UserFragment
        }
      }
    `;
    const result = await this.client.query({
      query,
      fetchPolicy: 'no-cache'
    });
    const {data, error, errors} = result;
    if (error) {
      throw error;
    } else if (errors?.length) {
      throw errors[0];
    } else {
      return new this(data?.sessionUser);
    }
  }

  static async start ({email, code}) {
    return this._mutate({
      name: 'startSession',
      variables: {email, code}
    });
  }

  static async refresh ({refresh_token}) {
    return this._mutate({
      name: 'refreshSession',
      variables: {refresh_token}
    });
  }

  static async end ({all = false} = {}) {
    return this._mutate({
      name: 'endSession',
      variables: {all}
    });
  }

  static async _mutate ({name, variables}) {
    const mutation = this._getMutation(name);
    const {
      data: mdata,
      errors
    } = await this.client.mutate({
      mutation,
      variables,
      fetchPolicy: 'no-cache'
    });

    if (errors?.length) {
      throw errors[0];
    }

    const data = mdata[name];
    if (data?.user) {
      data.user = new this(data.user);
    }
    return data;
  }

  static _getMutation (name) {
    return {
      endSession: gql`
        mutation endSession ($all: Boolean!) {
          endSession (all: $all) {
            active
            closed
          }
        }
      `,
      refreshSession: gql`
        ${this.UserFragment}
        mutation refreshSession (
          $refresh_token: RefreshToken!
        ) {
          refreshSession (
            refresh_token: $refresh_token
          ) {
            auth {
              token
              refresh_token
              app_tokens {
                name
                token
              }
            }
            user {
              ...UserFragment
            }
          }
        }
      `,
      startSession: gql`
        ${this.UserFragment}
        mutation startSession(
          $email: Email!
          $code: AuthCode!
        ) {
          startSession (
            email: $email
            code: $code
          ) {
            auth {
              token
              refresh_token
              app_tokens {
                name
                token
              }
            }
            user {
              ...UserFragment
            }
          }
        }
      `
    }[name];
  }

  get id () {
    return this.data?.id;
  }

  get email () {
    return this.data?.email;
  }

  get status () {
    return this.data?.status;
  }

  get role () {
    return this.data?.role;
  }

  get name () {
    return this.data?.name;
  }

  get username () {
    return this.data?.username;
  }

  isSignedIn () {
    return !!this.id;
  }

  isActive () {
    return (this.status === UserStatus.Active);
  }

  isAdmin () {
    return [UserRole.Admin, UserRole.SuperAdmin].includes(this.role);
  }
}
