import React from 'react';
import {TouchableOpacity} from 'react-native';

import Logo from './Logo';
import useRouter from './useRouter';

export default function HomeButton ({size = 24, ...props}) {
  const router = useRouter();
  return (
    <TouchableOpacity
      onPress={()=> {
        router.go('Home');
      }}
      {...props}
    >
      <Logo
        size={size}
      />
    </TouchableOpacity>
  );
}
