import React from 'react';
import {TouchableOpacity} from 'react-native';
import Styled from 'styled-components/native';

import {pollAttrForType} from '@hello10/shared';
import {
  Button,
  Field,
  IconButton,
  TextInput
} from '@hello10/shared-client';
import {PollType} from '../Enums.json';
import PollTypeChooser from './PollTypeChooser';
import PieceChooser from './PieceChooser';
import {ImagesChooser} from './PostHelpers';

const S = {
  Container: Styled.View``,
  TextOption: Styled.View`
    flex: 1 0 auto;
    flex-direction: row;
    margin-bottom: 4px;
  `,
  TextOptionInput: Styled(TextInput)`
    margin-right: 4px;
  `,
  AddTextOptionButton: Styled(Button)`
    margin-top: 4px;
    width: 128px;
  `,
  Delete: Styled(IconButton)``
};

function TextOption ({
  text,
  onChangeText,
  placeholder,
  remove,
  can_remove
}) {
  return (
    <S.TextOption>
      <S.TextOptionInput
        placeholder={placeholder}
        onChangeText={onChangeText}
        value={text}
      />
      {can_remove && (
        <S.Delete
          icon="MaterialIcons.delete"
          size={24}
          onPress={remove}
        />
      )}
    </S.TextOption>
  );
}

function TextOptions ({dab}) {
  const attr = pollAttrForType(PollType.Text);
  const [options, setOptions] = dab.attr(attr);

  function add () {
    setOptions(options.add(''));
  }

  const $options = options.map((option, index)=> {
    function replace (option) {
      setOptions(options.replace({index, by: option}));
    }

    function remove () {
      setOptions(options.remove({index}));
    }

    const placeholder = `Option ${index + 1}`;

    return (
      <TextOption
        key={index}
        text={option}
        placeholder={placeholder}
        can_remove={options.length > 2}
        onChangeText={replace}
        remove={remove}
      />
    );
  });

  return (
    <>
      {$options}
      <S.AddTextOptionButton
        size={Button.Size.Small}
        label="Add option"
        icon_right="MaterialIcons.add"
        onPress={add}
      />
    </>
  );
}

function PieceOptions ({dab}) {
  const attr = pollAttrForType(PollType.Piece);
  return (
    <PieceChooser
      attr={attr}
      numeric={true}
      dab={dab}
    />
  );
}

function ImageOptions ({dab}) {
  const attr = pollAttrForType(PollType.Image);
  return (
    <ImagesChooser
      width={400}
      thumb_size={96}
      attr={attr}
      dab={dab}
    />
  );
}

function PollOptions ({dab, type}) {
  if (!type) {
    return null;
  }

  const Options = {
    [PollType.Image]: ImageOptions,
    [PollType.Piece]: PieceOptions,
    [PollType.Text]: TextOptions
  }[type];

  return (
    <Field label="Options">
      <Options dab={dab}/>
    </Field>
  );
}

export default function PollEditor ({dab, ...props}) {
  const [type, setType] = dab.attr('poll_type');
  return (
    <S.Container {...props}>
      <Field label="Poll Type">
        <PollTypeChooser
          type={type}
          setType={setType}
        />
      </Field>
      <PollOptions
        type={type}
        dab={dab}
      />
    </S.Container>
  );
}

function placeholders () {
  return [
    '',
    ''
  ];
}

PollEditor.placeholders = placeholders;
