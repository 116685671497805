import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components/native';

import {Button} from '@hello10/shared-client';
import useRouter from './useRouter';

const S = {
  Menu: Styled.View`
    position: fixed;
    bottom: ${48 + 20}px;
    right: 8px;
    width: 200px;
    z-index: 10;
    elevation: 10;
  `,
  MenuButton: Styled(Button)`
    margin-top: 8px;
    width: 200px;
  `
};

export default function CreateMenu ({close, is_open}) {
  const router = useRouter();

  if (!is_open) {
    return null;
  }

  function closeAndGo (name) {
    return function handler () {
      close();
      router.go(name);
    };
  }

  return (
    <S.Menu>
      <S.MenuButton
        label="New Post"
        onPress={closeAndGo('CreatePost')}
        icon_left="MaterialCommunityIcons.text-subject"
      />
      <S.MenuButton
        label="New Piece"
        onPress={closeAndGo('CreatePiece')}
        icon_left="MaterialCommunityIcons.palette"
      />
      <S.MenuButton
        label="New Space"
        onPress={closeAndGo('CreateSpace')}
        icon_left="MaterialCommunityIcons.vector-square"
      />
    </S.Menu>
  );
}
CreateMenu.propTypes = {
  close: PropTypes.func,
  is_open: PropTypes.bool
};
