import React from 'react';
import Styled from 'styled-components/native';

const S = {
  Name: Styled.Text`
    margin-top: 8px;
    font-size: 32px;
    font-weight: bold;
  `
};

export function Name ({user, prefix = '', ...props}) {
  return (
    <S.Name {...props}>
      {prefix}{user.name}
    </S.Name>
  );
}
