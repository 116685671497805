import React from 'react';
import Styled from 'styled-components/native';

import {Link} from '../Application';
import {Title} from './PostHelpers';

const S = {
  Container: Styled.View``,
  Title: Styled(Title)``,
  NewPostTitle: Styled.Text`
    font-size: 24px;
    font-weight: bold;
    text-decoration: underline;
  `
};

function NewPost () {
  return (
    <Link route="CreatePost">
      <S.NewPostTitle>Create new post</S.NewPostTitle>
    </Link>
  );
}

export default function PostList ({posts}) {
  const $posts = posts.map((post)=> (
    <S.Title
      key={post.id}
      post={post}
    />
  ));

  return (
    <S.Container>
      <NewPost/>
      {$posts}
    </S.Container>
  );
}
