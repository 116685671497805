import React from 'react';
import Styled from 'styled-components/native';
import {dayAndTime} from '@hello10/jump-util';

import Enums from '../Enums.json';
import {
  Anchor,
  DabSelect,
  DabTextInput,
  ImageChooser,
  Field,
  SizedImageCarousel
} from '@hello10/shared-client';

function fontSize (size = 'Large') {
  return {
    Large: 32,
    Medium: 16,
    Small: 12
  }[size];
}

const S = {
  Description: Styled.Text``,
  Url: Styled(Anchor)`
    margin-top: 8px;
  `,
  Medium: Styled.Text`
    margin-top: 8px;
    width: 100px;
    text-align: center;
    border: 1px solid black;
    padding: 4px;
    border-radius: 2px;
  `,
  Title: Styled.Text`
    font-size: ${({size})=> fontSize(size)}px;
    margin-top: 8px;
    font-weight: bold;
  `,
  UpdatedAt: Styled.Text`
    margin-top: 8px;
  `
};

export function Title ({
  piece,
  prefix = '',
  ...props
}) {
  return (
    <S.Title {...props}>
      {prefix}{piece.title}
    </S.Title>
  );
}

export function Medium ({piece, ...props}) {
  return (
    <S.Medium {...props}>
      {piece.medium}
    </S.Medium>
  );
}

export function Url ({piece, ...props}) {
  const {url} = piece;
  if (!url) {
    return null;
  }
  return (
    <S.Url
      href={url}
      {...props}
    >
      {url}
    </S.Url>
  );
}

export function Description ({piece, ...props}) {
  const {description} = piece;
  if (!description) {
    return null;
  }
  return (
    <S.Description {...props}>
      {description}
    </S.Description>
  );
}

export function UpdatedAt ({piece, ...props}) {
  const {day, time} = dayAndTime(piece.updated_at);
  return (
    <S.UpdatedAt {...props}>
      Last updated {day} at {time}
    </S.UpdatedAt>
  );
}

export function MediumField ({dab, ...props}) {
  const options = Object.values(Enums.Medium);
  return (
    <Field
      label="Medium"
      {...props}
    >
      <DabSelect
        attr="medium"
        dab={dab}
        options={options}
        placeholder="Select a medium"
      />
    </Field>
  );
}

export function UrlField ({dab, ...props}) {
  return (
    <Field
      label="URL"
      {...props}
    >
      <DabTextInput
        attr="url"
        dab={dab}
      />
    </Field>
  );
}

export function SnapshotChooser ({dab, width, ...props}) {
  const [snapshot, setSnapshot] = dab.attr('snapshot');
  return (
    <ImageChooser
      media={snapshot}
      setMedia={setSnapshot}
      width={width}
      shape={ImageChooser.Shape.None}
      prompt="Add a snapshot"
      {...props}
    />
  );
}

export function SnapshotCarousel ({piece, ...props}) {
  return (
    <SizedImageCarousel
      images={piece.snapshots}
      {...props}
    />
  );
}
