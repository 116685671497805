import React, {useState} from 'react';
import Styled from 'styled-components/native';

import {
  Button,
  ButtonRow
} from '@hello10/shared-client';
import {useRouter} from '../Application';
import Comments from './Comments';

const S = {
  CommentsSummary: Styled.View``,
  ButtonRow: Styled(ButtonRow)``,
  Button: Styled(Button)`
    margin-right: 4px;
  `
};

export default function CommentsSummary ({post, space_id, ...props}) {
  const router = useRouter();
  const [show_create, setShowCreate] = useState(false);

  const {latest, count} = post.comments_summary;

  function gotoPost () {
    router.go({
      name: 'SpacePost',
      params: {
        post_id: post.id,
        space_id
      }
    });
  }

  const has_more = (count > latest.length);

  return (
    <S.CommentsSummary {...props}>
      <Comments
        post={post}
        comments={latest}
        space_id={space_id}
        show_create={show_create}
      />
      <S.ButtonRow>
        {has_more && (
          <S.Button
            label={`View all ${count} comments`}
            size={Button.Size.Small}
            onPress={gotoPost}
          />
        )}
        {!show_create && (
          <S.Button
            label="Reply"
            size={Button.Size.Small}
            onPress={()=> {
              setShowCreate(true);
            }}
          />
        )}
      </S.ButtonRow>
    </S.CommentsSummary>
  );
}
