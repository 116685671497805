import React, {useState} from 'react';
import Styled from 'styled-components/native';

import {
  Button,
  ButtonRow,
  Tabs
} from '@hello10/shared-client';
import {useRouter} from '../Application';
import {SpaceBanner} from '../Space';
import Comments from './Comments';

const S = {
  AuthorComments: Styled.View``,
  SpaceTab: Styled.View``,
  TabButtonRow: Styled(ButtonRow)`
    margin-bottom: 8px;
  `,
  TabButton: Styled(Button)`
    margin-right: 4px;
  `,
  Banner: Styled(SpaceBanner)`
    margin-left: 0px;
  `
};

function makeSpaceTab ({post, space, comments}) {
  function Tab (props) {
    const router = useRouter();

    return (
      <S.SpaceTab>
        <S.Banner
          space={space}
          post_id={post.id}
          width={344}
          edit={false}
        />
        <Comments
          post={post}
          comments={comments}
          space_id={space.id}
          {...props}
        />
      </S.SpaceTab>
    );
  }
  Tab.label = space.name;
  return Tab;
}

export default function AuthorComments ({post, ...props}) {
  const {comments_by_space} = post;
  const tabs = comments_by_space.map(({space, comments})=> (
    makeSpaceTab({post, space, comments})
  ));

  return (
    <S.AuthorComments {...props}>
      <Tabs tabs={tabs}/>
    </S.AuthorComments>
  );
}
