import {
  HomePage,
  FooPage
} from '../Home';
import {NotFoundPage} from '../NotFound';
import {
  CreatePiecePage,
  EditPiecePage,
  PiecePage,
  PiecesPage
} from '../Piece';
import {
  EditUserPage,
  InvitePage,
  SigninPage,
  SigninVerifyPage,
  UserPage,
  UserSetupPage,
  UserSuspendedPage,
  UserWaitlistedPage
} from '../User';
import {
  CreateSpacePage,
  EditSpacePage,
  SpacePage,
  SpacesPage
} from '../Space';
import {
  CreatePostPage,
  EditPostPage,
  PostPage,
  PostAuthorPage,
  PostsPage
} from '../Post';

const routes = {
  Home: {
    pattern: '/',
    page: HomePage,
    session: true
  },
  NotFound: {
    pattern: '/404',
    page: NotFoundPage,
    splash: true
  },
  Signin: {
    pattern: '/signin',
    page: SigninPage,
    session: false,
    splash: true
  },
  SigninVerify: {
    pattern: '/signin/verify',
    page: SigninVerifyPage,
    session: false,
    splash: true
  },
  User: {
    pattern: '/users/:user_id',
    page: UserPage,
    session: true
  },
  Settings: {
    pattern: '/settings/:tab?',
    page: EditUserPage,
    session: true
  },
  Invite: {
    pattern: '/invite/:invite_id',
    page: InvitePage,
    splash: true,
    redirect: ({input: {user}})=> {
      if (user.isSignedIn()) {
        return {
          name: 'Settings',
          params: {
            tab: 'invites'
          }
        };
      } else {
        return false;
      }
    }
  },
  UserSetup: {
    pattern: '/setup',
    page: UserSetupPage,
    session: true,
    splash: true
  },
  UserSuspended: {
    pattern: '/suspended',
    page: UserSuspendedPage,
    session: true,
    splash: true
  },
  UserWaitlisted: {
    pattern: '/waitlisted',
    page: UserWaitlistedPage,
    session: true,
    splash: true
  },
  Pieces: {
    pattern: '/studio/pieces',
    page: PiecesPage,
    session: true
  },
  CreatePiece: {
    pattern: '/studio/pieces/new',
    page: CreatePiecePage,
    session: true
  },
  // TODO: check ownership
  Piece: {
    pattern: '/studio/pieces/:piece_id',
    page: PiecePage,
    session: true
  },
  // TODO: check ownership
  EditPiece: {
    pattern: '/studio/pieces/:piece_id/edit',
    page: EditPiecePage,
    session: true
  },
  Posts: {
    pattern: '/posts',
    page: PostsPage,
    session: true
  },
  CreatePost: {
    pattern: '/posts/new',
    page: CreatePostPage,
    session: true
  },
  Post: {
    pattern: '/posts/:post_id',
    page: PostAuthorPage,
    session: true
  },
  EditPost: {
    pattern: '/posts/:post_id/edit',
    page: EditPostPage,
    session: true
  },
  Spaces: {
    pattern: '/spaces',
    page: SpacesPage,
    session: true
  },
  CreateSpace: {
    pattern: '/spaces/new',
    page: CreateSpacePage,
    session: true,
    admin: true // Temporary until we open invites to non-admins
  },
  // TODO: check membership
  Space: {
    pattern: '/spaces/:space_id',
    page: SpacePage,
    session: true
  },
  SpacePost: {
    pattern: '/spaces/:space_id/posts/:post_id',
    page: PostPage,
    session: true
  },
  // TODO: check membership
  EditSpace: {
    pattern: '/spaces/:space_id/edit/:tab?',
    page: EditSpacePage,
    session: true
  },
  Foo: {
    pattern: '/foo',
    page: FooPage,
    session: true,
    admin: true
  }
};

export default routes;
