import React, {useState} from 'react';
import {useQuery} from '@apollo/client';
import Styled from 'styled-components/native';
import {
  isUrl,
  nonempty,
  mapp
} from '@hello10/jump-util';

import {validatePost} from '@hello10/shared';
import {
  Button,
  ButtonRow,
  Field,
  MutationStatus,
  uploadImage,
  useDab,
  useMutation
} from '@hello10/shared-client';
import {
  useRouter,
  useSession
} from '../Application';
import {PostType, PollType} from '../Enums.json';
import PostTypeChooser from './PostTypeChooser';
import SpaceChooser from './SpaceChooser';
import PollEditor from './PollEditor';
import PostEditor from './PostEditor';

import {
  CreatePostMutation,
  PostFragment
} from './PostGQL';

const width = 400;

const S = {
  Container: Styled.View`
    width: ${width}px;
  `,
  PieceButton: Styled(Button)`
    margin-top: 4px;
    width: 144px;
  `,
  Text: Styled.Text``
};

export default function CreatePostPage ({params}) {
  const router = useRouter();
  const session = useSession();

  const space_ids = [];
  if (params.space) {
    space_ids.push(params.space);
  }

  let type = null;
  const piece_ids = [];
  if (params.piece) {
    type = PostType.Piece;
    piece_ids.push(params.piece);
  }

  const dab = useDab({
    initial: {
      type,
      title: null,
      text: null,
      url: null,
      space_ids,
      piece_ids,
      images: [],
      poll_type: null,
      multi: false,
      anonymous: false,
      texts: PollEditor.placeholders()
    },
    formatAll: ({data})=> {
      const {
        poll_type,
        multi,
        anonymous,
        texts,
        images,
        piece_ids,
        ...formatted
      } = data;

      formatted.author_id = session.user.id;

      if (data.type === PostType.Poll) {
        const poll = {
          type: poll_type,
          multi,
          anonymous
        };
        if (poll_type === PollType.Text) {
          poll.texts = texts;
        } else if (poll_type === PollType.Piece) {
          poll.piece_ids = piece_ids;
        } else if (poll_type === PollType.Image) {
          poll.images = images;
        }
        formatted.poll = poll;
      } else {
        formatted.images = images;
        formatted.piece_ids = piece_ids;
      }
      return formatted;
    },
    require: ['type', 'title', 'space_ids'],
    validateAll: ({formatted})=> {
      return validatePost(formatted);
    }
  });

  const mutation = useMutation({
    mutation: CreatePostMutation,
    update (cache, {data: {createPost: post}}) {
      const created = cache.writeFragment({
        data: post,
        fragment: PostFragment,
        fragmentName: 'PostFragment'
      });

      cache.modify({
        id: `User:${session.user.id}`,
        fields: {
          posts: (existing)=> [created, ...existing],
          dashboard (existing) {
            return {
              ...existing,
              posts: [created, ...existing.posts]
            };
          }
        }
      });

      // This is a shitty hack because apollo is a fucking piece of
      // shit and doesn't make it possible to invalidate a query in
      // any sensible way FUCK U APOLLO WITH THE FIRE OF 1000 SUNS
      for (const space_id of post.space_ids) {
        cache.modify({
          id: `Space:${space_id}`,
          fields: {
            posts (existing) {
              return {
                cursor: {
                  ...existing.cursor,
                  start: post.created_at,
                  count: existing.cursor.count + 1
                },
                items: [created, ...existing.items],
                replace: true
              };
            }
          }
        });
        // cache.modify({
        //   id: `Space:${space_id}`,
        //   fields: (_, details)=> details.INVALIDATE
        // });
        // cache.modify({
        //   id: `Space:${space_id}`,
        //   fields: {
        //     posts: (_, details)=> details.INVALIDATE
        //   }
        // });
      }
    }
    // reobserveQuery (query) {
    //   return query.refetch();
    // }
  });

  async function create () {
    const data = dab.formatted;

    // 1. upload images
    function upload (images) {
      mutation.setStatus('Uploading');
      return mapp(images, (image)=> (
        uploadImage({
          image,
          onProgress: mutation.setProgress
        })
      ));
    }

    const {type, poll} = data;
    const is_image = (type === PostType.Image);
    const is_poll = (type === PostType.Poll);
    const is_image_poll = is_poll && (poll.type === PollType.Image);
    if (is_image) {
      data.images = await upload(data.images);
    }
    if (is_image_poll) {
      data.poll.images = await upload(data.poll.images);
    }

    // 2. run mutation
    mutation.setStatus('Creating post');
    try {
      const result = await mutation.mutate({variables: {data}});
      const post_id = result?.data?.createPost?.id;
      if (post_id) {
        router.go({
          name: 'Post',
          params: {post_id}
        });
      }
    } catch (error) {
      console.error(error);
      mutation.setStatus('Pending');
    }
  }

  return (
    <S.Container>
      <Field label="Spaces">
        <SpaceChooser dab={dab}/>
      </Field>
      <Field label="Type">
        <PostTypeChooser {...dab.attro('type')}/>
      </Field>
      <PostEditor
        dab={dab}
        type={dab.data.type}
        width={width}
        mode={PostEditor.Mode.Create}
      />
      <ButtonRow>
        <Button
          label="Create"
          disabled={!dab.is_valid || !mutation.pending}
          onPress={create}
          icon_right="MaterialIcons.done"
        />
      </ButtonRow>
      <MutationStatus mutation={mutation}/>
    </S.Container>
  );
}
