import useRouter from './useRouter';

export default function useGoPress () {
  const router = useRouter();
  return function goPress (arg) {
    return function go () {
      router.go(arg);
    };
  };
}
