import React from 'react';
import {TouchableOpacity} from 'react-native';
import Styled from 'styled-components/native';
import {bit} from '@hello10/jump-util';

import {
  CheckIcon,
  Loading,
  useMutation
} from '@hello10/shared-client';
import {useSession} from '../Application';
import {PollType} from '../Enums.json';
import {
  PieceCarousel,
  ImageCarousel
} from './PostHelpers';
import {TogglePollVoteMutation} from './PostGQL';


const S = {
  Container: Styled.View`
    width: ${({width})=> width}px;
  `,
  Option: Styled(TouchableOpacity)`
    border-width: 1px;
    border-style: solid;
    border-color: ${({selected})=> selected ? 'black' : '#00000033'}
    border-radius: 2px;
    padding: 4px;
    flex: 1 0 auto;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 4px;
    margin-bottom: 4px;
  `,
  Text: Styled.Text``,
  Label: Styled.View`
    flex: 1 0 auto;
    flex-direction: row;
  `,
  LabelText: Styled.Text`
    margin-left: 4px;
  `,
  Percent: Styled.Text``
};

function Option ({
  option,
  selected,
  has_voted,
  votes,
  total,
  number,
  ...props
}) {
  const {text, title} = option;

  let $percent = null;
  if (has_voted) {
    const percent = Math.round((votes.length / total) * 100);
    $percent = (
      <S.Percent>{percent}%</S.Percent>
    );
  }

  return (
    <S.Option
      {...props}
    >
      <S.Label>
        <CheckIcon
          selected={selected}
          size={16}
          color={selected ? 'black' : '#00000033'}
        />
        <S.LabelText>{number}. {text || title}</S.LabelText>
      </S.Label>
      {$percent}
    </S.Option>
  );
}

export default function Poll ({post, width, ...props}) {
  const session = useSession();
  const mutation = useMutation(TogglePollVoteMutation);

  const session_uid = session.user.id;

  const {
    id: post_id,
    poll
  } = post;
  if (!poll) {
    return null;
  }

  const {type, votes, options} = poll;
  const is_piece = (type === PollType.Piece);
  const is_image = (type === PollType.Image);

  const option_voted_for = {};
  const option_votes = {};
  const total = votes.length;
  let has_voted = false;
  for (const vote of votes) {
    const {option_id, voter_id} = vote;
    if (voter_id === session_uid) {
      option_voted_for[option_id] = true;
      has_voted = true;
    }
    if (option_id in option_votes) {
      option_votes[option_id].push(vote);
    } else {
      option_votes[option_id] = [vote];
    }
  }

  const {loading} = mutation;

  const $options = options.map((option, index)=> {
    const {id: option_id} = option;
    const selected = option_voted_for[option_id];
    const votes = option_votes[option_id] || [];
    return (
      <Option
        number={index + 1}
        total={total}
        option={option}
        has_voted={bit(has_voted)}
        votes={votes}
        key={option_id}
        selected={bit(selected)}
        disabled={loading}
        onPress={()=> {
          mutation.mutate({
            variables: {
              voter_id: session_uid,
              post_id,
              option_id
            }
          });
        }}
      />
    );
  });

  return (
    <S.Container
      width={width}
      {...props}
    >
      {is_piece && (
        <PieceCarousel
          pieces={options}
          width={width}
        />
      )}
      {is_image && (
        <ImageCarousel
          images={options}
          width={width}
        />
      )}
      {$options}
      {loading && <Loading text="Loading results"/>}
    </S.Container>
  );
}
