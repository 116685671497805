import * as React from 'react';

import {Application} from './src/Application';

// function wdyr () {
//   // eslint-disable-next-line global-require
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   whyDidYouRender(
//     React,
//     {
//       trackAllPureComponents: true
//     }
//   );
// }
//
// // eslint-disable-next-line no-undef
// if (__DEV__) {
//   wdyr();
// }


export default Application;
