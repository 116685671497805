import React from 'react';
import Styled from 'styled-components/native';

import {GetPostsQuery} from './PostGQL';
import PostList from './PostList';

const S = {
  Container: Styled.View``
};

export default function PostsPage ({data}) {
  const {posts} = data.sessionUser;

  return (
    <S.Container>
      <PostList posts={posts}/>
    </S.Container>
  );
}

PostsPage.query = function query () {
  return {
    query: GetPostsQuery
  };
};
