import React from 'react';
import Styled from 'styled-components/native';

import {Button} from '@hello10/shared-client';
import {useGoPress} from '../Application';
import {PieceList} from '../Piece';
import {PostList} from '../Post';
import {SpaceList} from '../Space';
import {DashboardQuery} from './HomeGQL';

const S = {
  Container: Styled.View`
  `,
  Section: Styled.View`
    margin-bottom: 32px;
  `,
  Header: Styled.Text`
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 16px;
  `,
  Button: Styled(Button)`
    margin-top: 8px;
    width: 200px;
  `
};

function Section ({header, name, view_all_label, children}) {
  const goPress = useGoPress();

  if (!view_all_label) {
    view_all_label = `All ${name}`;
  }

  return (
    <S.Section>
      <S.Header>{header}</S.Header>
      {children}
      <S.Button
        icon_right="MaterialIcons.chevron-right"
        label={view_all_label}
        onPress={goPress(name)}
      />
    </S.Section>
  );
}

function Pieces ({pieces}) {
  return (
    <Section
      header="Your studio"
      name="Pieces"
      view_all_label="Open Studio"
    >
      <PieceList
        pieces={pieces}
        size="Medium"
      />
    </Section>
  );
}

function Spaces ({spaces}) {
  return (
    <Section
      header="Your community"
      name="Spaces"
    >
      <SpaceList
        spaces={spaces}
      />
    </Section>
  );
}

function Posts ({posts}) {
  return (
    <Section
      header="Your recently active posts"
      name="Posts"
    >
      <PostList
        posts={posts}
      />
    </Section>
  );
}

export default function HomePage ({data}) {
  const {pieces, posts, spaces} = data.sessionUser.dashboard;
  return (
    <S.Container>
      <Pieces pieces={pieces}/>
      <Spaces spaces={spaces}/>
      <Posts posts={posts}/>
    </S.Container>
  );
}

HomePage.query = function query () {
  return {
    query: DashboardQuery
  };
};
