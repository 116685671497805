import React from 'react';
import Styled from 'styled-components/native';

const S = {
  FooPage: Styled.View``,
  Hello: Styled.Text`
    font-size: 32px;
  `
};

export default function FooPage () {
  return (
    <S.FooPage>
      <S.Hello>Hello</S.Hello>
    </S.FooPage>
  );
}
