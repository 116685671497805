import gql from 'graphql-tag';

export const SpacesNavQuery = gql`
  query getSpaces {
    sessionUser {
      id
      spaces {
        id
        name
      }
    }
  }
`;

export const PostsNavQuery = gql`
  query getPosts {
    sessionUser {
      id
      posts {
        id
        title
      }
    }
  }
`;

export const PiecesNavQuery = gql`
  query getPieces {
    sessionUser {
      id
      pieces {
        id
        title
      }
    }
  }
`;

export const PostNavQuery = gql`
  query getPost ($id: ObjectID!) {
    getPost (id: $id) {
      id
      title
    }
  }
`;

export const SpaceNavQuery = gql`
  query getSpace ($id: ObjectID!) {
    getSpace (id: $id) {
      id
      name
    }
  }
`;

export const UserNavQuery = gql`
  query getUser ($id: ObjectID!) {
    getUser (id: $id) {
      id
      username
    }
  }
`;
