import React from 'react';
import Styled from 'styled-components/native';

import {Splash} from '../Application';

export default function UserWaitlistedPage () {
  return (
    <Splash
      header="Thanks for signing up"
      details="You've been added to the waitlist."
    />
  );
}
