import React from 'react';
import Styled from 'styled-components/native';

import {Avatar} from '@hello10/shared-client';

const S = {
  Members: Styled.View`
    margin-top: 4px;
    flex: 1 0 auto;
    flex-direction: row;
  `,
  Avatar: Styled(Avatar)`
    margin-right: 4px;
  `
};

function Member ({member}) {
  return (
    <S.Avatar
      user={member}
      size={32}
    />
  );
}

export default function Members ({group}) {
  const {memberships} = group;
  [...memberships].sort((a, b)=> (
    new Date(b.last_active_at).getTime() - new Date(a.last_active_at).getTime()
  ));
  const $members = group.memberships.map(({id, member})=> (
    <Member
      key={id}
      member={member}
    />
  ));
  return (
    <S.Members>
      {$members}
    </S.Members>
  );
}
