import React from 'react';
import {TouchableOpacity} from 'react-native';
import Styled from 'styled-components/native';
import {useQuery} from '@apollo/client';

import {Select} from '@hello10/shared-client';
import {
  SpacesNavQuery,
  PostsNavQuery,
  PiecesNavQuery,
  PostNavQuery,
  SpaceNavQuery,
  UserNavQuery
} from './HeaderGQL';
import HomeButton from './HomeButton';
import useRouter from './useRouter';

// TODO: is there a reason to delegate to individual pages to
//       provide their nav?

const S = {
  Container: Styled.View`
    padding: 8px;
    flex: 1 0 auto;
    flex-direction: row;
    align-items: center;
  `,
  Home: Styled(HomeButton)`
    margin-right: 4px;
  `,
  NavItem: Styled.View`
    margin-left: 4px;
    margin-right: 4px;
  `,
  Select: Styled(Select)``,
  Divider: Styled.Text``,
  Text: Styled.Text``
};

export default function Header () {
  return (
    <S.Container>
      <S.Home size={32}/>
      <Nav/>
    </S.Container>
  );
}

function Nav () {
  const router = useRouter();
  const {
    match: {
      params,
      route
    }
  } = router;

  const parts = route.pattern.split('/').slice(1);
  const last = parts[parts.length - 1];

  const nav_args = {
    params,
    route,
    parts,
    is_edit: (last === 'edit'),
    is_new: (last === 'new'),
    is_show: last.match(/^:.*id$/)
  };

  let $items;
  switch (route.name) {
    // TODO: fix router 404 bug on `/settings/:tab?`
    case 'Settings':
      $items = [
        <NavItem
          key="settings"
          label="Settings"
        />
      ];
      break;

    case 'User':
      $items = userNavItems(nav_args);
      break;

    case 'Pieces':
    case 'CreatePiece':
    case 'Piece':
    case 'EditPiece':
      $items = studioNavItems(nav_args);
      break;

    case 'Posts':
    case 'CreatePost':
    case 'Post':
    case 'EditPost':
      $items = postsNavItems(nav_args);
      break;

    case 'Spaces':
    case 'CreateSpace':
    case 'Space':
    case 'SpacePost':
    case 'EditSpace':
      $items = spacesNavItems(nav_args);
      break;

    default:
      $items = [];
      break;
  }

  $items = $items.flatMap((item, i)=> {
    return [
      item,
      <Divider key={`divider_${i}`}/>
    ];
  });
  $items.pop();

  return (
    <>
      {$items}
    </>
  );
}

function studioNavItems ({
  params,
  route,
  is_new,
  is_edit,
  is_show
}) {
  const $items = [
    <NavItem
      key="studio"
      label="Studio"
      route={{
        name: 'Pieces'
      }}
    />
  ];

  if (is_new) {
    $items.push(
      <NavItem
        key="new"
        label="New"
      />
    );
  }

  if (is_show || is_edit) {
    $items.push(
      <PieceSelect
        key="select"
        params={params}
        route={route}
      />
    );
  }

  if (is_edit) {
    $items.push(
      <NavItem
        key="edit"
        label="Edit"
      />
    );
  }

  return $items;
}

function spacesNavItems ({
  params,
  route,
  is_new
}) {
  let $items = [
    <NavItem
      key="spaces"
      label="Spaces"
      route={{
        name: 'Spaces'
      }}
    />
  ];

  if (is_new) {
    $items.push(
      <NavItem
        key="new"
        label="New"
      />
    );
  }

  if (['Space', 'EditSpace'].includes(route.name)) {
    $items.push(
      <SpaceSelect
        key="select"
        params={params}
        route={route}
      />
    );
    if (route.name === 'EditSpace') {
      $items.push(
        <NavItem
          key="edit"
          label="Edit"
        />
      );
    }
  }

  if (route.name === 'SpacePost') {
    const {space_id, post_id} = params;
    $items = [
      ...$items,
      <SpaceLink
        key="space"
        space_id={space_id}
      />,
      <PostTitle
        key="post"
        post_id={post_id}
      />
    ];
  }

  return $items;
}

function postsNavItems ({
  params,
  route,
  is_new,
  is_edit,
  is_show
}) {
  const $items = [
    <NavItem
      key="posts"
      label="Posts"
      route={{
        name: 'Posts'
      }}
    />
  ];

  if (is_new) {
    $items.push(
      <NavItem
        key="new"
        label="New"
      />
    );
  }

  if (is_show || is_edit) {
    $items.push(
      <PostSelect
        key="select"
        params={params}
        route={route}
      />
    );
  }

  if (is_edit) {
    $items.push(
      <NavItem
        key="edit"
        label="Edit"
      />
    );
  }

  return $items;
}

function userNavItems ({params}) {
  return [
    <NavItem
      key="users"
      label="Users"
    />,
    <Username
      key="username"
      user_id={params.user_id}
    />
  ];
}

function Loading () {
  return (
    <S.Text>...</S.Text>
  );
}

function Divider () {
  return (
    <S.Divider>/</S.Divider>
  );
}

function NavItem ({label, route, children, ...props}) {
  const router = useRouter();

  if (!children) {
    if (label) {
      children = (
        <S.Text>{label}</S.Text>
      );
    }

    if (route) {
      children = (
        <TouchableOpacity
          onPress={()=> router.go(route)}
        >
          {children}
        </TouchableOpacity>
      );
    }
  }

  return (
    <S.NavItem {...props}>
      {children}
    </S.NavItem>
  );
}

function SpaceSelect (props) {
  return (
    <NavSelect
      query={SpacesNavQuery}
      items={(data)=> data.sessionUser.spaces}
      label_attr="name"
      param_name="space_id"
      {...props}
    />
  );
}

function SpaceLink ({space_id}) {
  return (
    <NavText
      query={SpaceNavQuery}
      variables={{id: space_id}}
      label={(data)=> data.getSpace.name}
      route={{
        name: 'Space',
        params: {space_id}
      }}
    />
  );
}

function PostTitle ({post_id}) {
  return (
    <NavText
      query={PostNavQuery}
      variables={{id: post_id}}
      label={(data)=> data.getPost.title}
    />
  );
}

function Username ({user_id}) {
  return (
    <NavText
      query={UserNavQuery}
      variables={{id: user_id}}
      label={(data)=> data.getUser.username}
    />
  );
}

function NavText ({
  query: gql,
  variables,
  label: getLabel,
  route
}) {
  const query = useQuery(gql, {variables});

  if (query.loading || query.error) {
    return <Loading/>;
  }

  const label = getLabel(query.data);
  return (
    <NavItem
      label={label}
      route={route}
    />
  );
}

function PostSelect (props) {
  return (
    <NavSelect
      query={PostsNavQuery}
      items={(data)=> data.sessionUser.posts}
      label_attr="title"
      param_name="post_id"
      {...props}
    />
  );
}

function PieceSelect (props) {
  return (
    <NavSelect
      query={PiecesNavQuery}
      items={(data)=> data.sessionUser.pieces}
      label_attr="title"
      param_name="piece_id"
      {...props}
    />
  );
}

function NavSelect ({
  query: gql,
  items: getItems,
  label_attr,
  params,
  route,
  param_name
}) {
  const id = params[param_name];
  const router = useRouter();
  const query = useQuery(gql);

  if (query.loading || query.error) {
    return <Loading/>;
  }

  const items = getItems(query.data);
  const options = items.map((item)=> (
    {
      label: item[label_attr],
      value: item.id
    }
  ));

  const onSelect = (id)=> {
    router.go({
      name: route.name,
      params: {
        ...params,
        [param_name]: id
      }
    });
  };

  return (
    <NavItem>
      <Select
        selected={id}
        isSelected={(option)=> (option.value === id)}
        options={options}
        onSelect={onSelect}
      />
    </NavItem>
  );
}
