import React from 'react';
import {TouchableOpacity} from 'react-native';
import Styled from 'styled-components/native';

import {Button} from '@hello10/shared-client';
import {useRouter} from '../Application';
import {DashedBox} from '../Shared';
import Members from './Members';
import SpaceCover from './SpaceCover';

const S = {
  SpaceList: Styled.View`
    flex: 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
  `,
  SpaceCard: Styled(TouchableOpacity)`
    margin-right: 16px;
    margin-bottom: 16px;
  `,
  NewSpaceCard: Styled(DashedBox)`
    margin-right: 16px;
    margin-bottom: 16px;
  `
};

function SpaceCard ({space, size}) {
  const router = useRouter();
  const {id} = space;
  return (
    <S.SpaceCard
      onPress={()=> {
        router.go(`/spaces/${id}`);
      }}
    >
      <SpaceCover
        space={space}
        width={size}
        shape={SpaceCover.Shape.Square}
      />
      <Members group={space}/>
    </S.SpaceCard>
  );
}

function NewSpaceCard ({size}) {
  return (
    <S.NewSpaceCard
      size={size}
      route="CreateSpace"
      text="Create new space"
    />
  );
}

export default function SpaceList ({spaces, size = 250}) {
  const $spaces = spaces.map((space)=> (
    <SpaceCard
      space={space}
      key={space.id}
      size={size}
    />
  ));

  return (
    <S.SpaceList>
      <NewSpaceCard
        key="new"
        size={size}
      />
      {$spaces}
    </S.SpaceList>
  );
}
