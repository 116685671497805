import React from 'react';
import {TouchableOpacity} from 'react-native';
import {useQuery} from '@apollo/client';
import Styled from 'styled-components/native';

import {CheckButton} from '@hello10/shared-client';
import {UserSpacesQuery} from '../User';
import Chooser from './Chooser';

const S = {
  Chooser: Styled(Chooser)`
    flex: 1 0 auto;
    flex-direction: row;
    flex-wrap: wrap;
    width: 420px;
  `
};

function SpaceButton ({space, ...props}) {
  return (
    <CheckButton
      label={space.name}
      {...props}
    />
  );
}

export default function SpaceChooser (props) {
  return (
    <S.Chooser
      type="Space"
      query={UserSpacesQuery}
      Button={SpaceButton}
      {...props}
    />
  );
}
