import React from 'react';
import Styled from 'styled-components/native';

import {Splash} from '../Application';
import {SpaceCover} from '../Space';
import {InviteStatus} from '../Enums.json';
import Signin from './Signin';
import {GetInviteQuery} from './UserGQL';

const S = {
  Text: Styled.Text`
    margin-top: 8px;
    font-size: 20px;
    text-align: center;
  `,
  Cover: Styled(SpaceCover)`
    margin-top: 8px;
  `,
  Inviter: Styled.Text`
    font-weight: bold;
    margin-right: 4px;
  `
};

export default function InvitePage ({params, data}) {
  //const router = useRouter();
  const {email, code} = params;
  const invite = data.getInvite;

  if (invite?.status !== InviteStatus.Open) {
    return (
      <Splash
        header="Invite not found."
      />
    );
  }

  const {inviter, group} = invite;

  const details = (
    <>
      <S.Text>
        <S.Inviter>{inviter.name}</S.Inviter>
        invited you to join
      </S.Text>
      <S.Cover
        space={group}
        width={400}
        height={100}
        shape={SpaceCover.Shape.Rectangle}
        edit={false}
      />
      <S.Text>
        on Arcs, a creative collaboration platform.
      </S.Text>
    </>
  );

  return (
    <Signin
      header="You're invited to join Arcs."
      label="ACCEPT INVITE"
      details={details}
      email={email}
      invite_id={invite.id}
      invite_code={code}
    />
  );
}

InvitePage.query = function query ({params}) {
  return {
    query: GetInviteQuery,
    variables: {
      id: params.invite_id
    }
  };
};
