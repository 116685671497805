import React from 'react';
import Styled from 'styled-components/native';

const S = {
  Spaces: Styled.View``,
  Derp: Styled.Text``
};

export default function SpacesTab () {
  return (
    <S.Spaces>
      <S.Derp>spaces!</S.Derp>
    </S.Spaces>
  );
}
SpacesTab.label = 'spaces';
