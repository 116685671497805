import React from 'react';
import {TouchableOpacity} from 'react-native';
import Styled from 'styled-components/native';

import {SizedImage} from '@hello10/shared-client';
import {useRouter} from '../Application';
import {DashedBox} from '../Shared';
import {Title} from './PieceHelpers';

const S = {
  PieceList: Styled.View`
    flex: 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
  `,
  PieceCard: Styled(TouchableOpacity)`
    margin-right: 8px;
    margin-bottom: 8px;
    width: ${({width})=> width}px;
  `,
  NewPieceCard: Styled(DashedBox)`
    margin-right: 8px;
    margin-bottom: 8px;
  `
};

function getWidth (size) {
  return {
    Large: 320,
    Medium: 160,
    Small: 64
  }[size];
}

function PieceCard ({piece, size, square}) {
  const router = useRouter();
  const {
    id: piece_id,
    snapshot
  } = piece;

  const width = getWidth(size);
  const dims = {width};
  if (square) {
    dims.height = width;
  }

  return (
    <S.PieceCard
      onPress={()=> {
        router.go({
          name: 'Piece',
          params: {piece_id}
        });
      }}
      width={width}
    >
      <SizedImage
        border={true}
        image={snapshot}
        {...dims}
      />
      <Title
        piece={piece}
        size={size}
      />
    </S.PieceCard>
  );
}

function NewPieceCard ({size}) {
  size = getWidth(size);

  return (
    <S.NewPieceCard
      size={size}
      route="CreatePiece"
      text="Create new piece"
    />
  );
}

export default function PieceList ({
  pieces,
  square = true,
  size = 'Large'
}) {
  const $pieces = pieces.map((piece)=> (
    <PieceCard
      piece={piece}
      key={piece.id}
      size={size}
      square={square}
    />
  ));

  return (
    <S.PieceList>
      <NewPieceCard
        key="new"
        size={size}
      />
      {$pieces}
    </S.PieceList>
  );
}
