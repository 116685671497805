import gql from 'graphql-tag';

export const ImageFragment = gql`
  fragment ImageFragment on Image {
    id
    aspect
    sizes {
      url
      type
      width
      height
    }
  }
`;

export const PieceFragment = gql`
  ${ImageFragment}

  fragment PieceFragment on Piece {
    id
    title
    url
    medium
    description
    updated_at
    snapshot {
      ...ImageFragment
    }
    snapshots {
      ...ImageFragment
    }
  }
`;

export const UserFragment = gql`
  ${ImageFragment}

  fragment UserFragment on User {
    id
    email
    name
    username
    role
    status
    avatar {
      ...ImageFragment
    }
  }
`;

export const UserDisplayFragment = gql`
  ${ImageFragment}

  fragment UserDisplayFragment on User {
    id
    name
    username
    avatar {
      ...ImageFragment
    }
  }
`;

export const InviteFragment = gql`
  ${UserDisplayFragment}

  fragment InviteFragment on Invite {
    id
    created_at
    status
    type
    email
    phone
    invitee {
      ...UserDisplayFragment
    }
    inviter {
      ...UserDisplayFragment
    }
  }
`;

export const CommentFragment = gql`
  ${UserDisplayFragment}

  fragment CommentFragment on Comment {
    id
    text
    author {
      ...UserDisplayFragment
    }
    space_id
    post_id
    created_at
    edited
  }
`;

export const PostFragment = gql`
  ${ImageFragment}
  ${UserDisplayFragment}

  fragment PostPieceFragment on Piece {
    id
    title
    snapshot {
      ...ImageFragment
    }
  }

  fragment PostFragment on PostInterface {
    id
    title
    type
    is_question
    text
    created_at
    space_ids
    author {
      ...UserDisplayFragment
    }
    ... on LinkPost {
      url
    }
    ... on PiecePost {
      piece_ids
      pieces {
        ...PostPieceFragment
      }
    }
    ... on ImagePost {
      image_ids
      images {
        ...ImageFragment
      }
    }
    ... on PollPost {
      poll {
        type
        multi
        anonymous
        options {
          ... on PollText {
            id
            text
          }
          ... on Piece {
            ...PostPieceFragment
          }
          ... on Image {
            ...ImageFragment
          }
        }
        votes {
          voter {
            ...UserDisplayFragment
          }
          voter_id
          option_id
          voted_at
        }
      }
    }
  }
`;

export const MembershipFragment = gql`
  ${ImageFragment}
  fragment MembershipFragment on Membership {
    id
    role
    member {
      id
      name
      avatar {
        ...ImageFragment
      }
    }
    last_active_at
  }
`;

export const SpaceFragment = gql`
  ${ImageFragment}
  ${MembershipFragment}

  fragment SpaceFragment on Space {
    id
    name
    cover {
      ...ImageFragment
    }
    memberships {
      ...MembershipFragment
    }
  }
`;
