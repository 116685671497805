import React from 'react';
import Styled from 'styled-components/native';
import {buildEnum} from '@hello10/jump-util';

import {TitleField} from '@hello10/shared-client';
import {PostType} from '../Enums.json';
import {
  PostTextEditor,
  PieceField,
  UrlField,
  ImagesChooser
} from './PostHelpers';
import PollEditor from './PollEditor';

const S = {
  Container: Styled.View`
    width: ${({width})=> width}px;
  `,
  ImagesChooser: Styled(ImagesChooser)`
    margin-top: 8px;
  `,
  Text: Styled.Text``
};

const Mode = buildEnum(['Edit', 'Create']);

export default function PostEditor ({
  dab,
  type,
  width,
  mode
}) {
  if (!type) {
    return null;
  }

  let $body = null;
  const is_edit = (mode === Mode.Edit);
  const is_piece = (type === PostType.Piece);
  const is_link = (type === PostType.Link);
  const is_poll = (type === PostType.Poll);
  const is_image = (type === PostType.Image);
  if (is_piece) {
    $body = (
      <PieceField dab={dab}/>
    );
  } else if (is_link) {
    $body = (
      <UrlField dab={dab}/>
    );
  } else if (is_poll) {
    $body = is_edit ? (
      <S.Text>Poll title and options cannot be edited</S.Text>
    ) : (
      <PollEditor dab={dab}/>
    );
  } else if (is_image) {
    $body = is_edit ? (
      <S.Text>Image(s) cannot be edited</S.Text>
    ) : (
      <S.ImagesChooser
        dab={dab}
        attr="images"
        width={width}
        thumb_size={128}
      />
    );
  }

  return (
    <S.Container width={width}>
      {!(is_edit && is_poll) && (
        <TitleField dab={dab}/>
      )}
      {$body}
      <PostTextEditor dab={dab}/>
    </S.Container>
  );
}

PostEditor.Mode = Mode;
