import React from 'react';
import Styled from 'styled-components/native';

import {
  IconButton,
  SizedImage
} from '@hello10/shared-client';
import {useRouter} from '../Application';

const S = {
  SpaceCover: Styled(SizedImage)`
    justify-content: center;
    align-content: center;
  `,
  SpaceNoCover: Styled.View`
    justify-content: center;
    align-content: center;
    background-color: black;
    width: ${({width})=> width}px;
    height: ${({width, height})=> height || width}px;
  `,
  SpaceCoverName: Styled.Text`
    color: white;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    text-shadow: 0px 0px 10px #00000055;
  `,
  EditButton: Styled(IconButton)`
    position: absolute;
    top: 4px;
    right: 4px;
  `
};

export default function SpaceCover ({space, edit, ...props}) {
  const router = useRouter();
  const {id, cover, name} = space;
  let $edit = null;
  if (edit) {
    $edit = (
      <S.EditButton
        label="Edit"
        icon="MaterialIcons.settings"
        size={24}
        onPress={()=> {
          router.go(`/spaces/${id}/edit`);
        }}
      />
    );
  }

  props.children = (
    <S.SpaceCoverName>
      {name}
      {$edit}
    </S.SpaceCoverName>
  );

  if (cover) {
    return (
      <S.SpaceCover
        border={true}
        image={cover}
        {...props}
      />
    );
  } else {
    return (
      <S.SpaceNoCover
        {...props}
      />
    );
  }
}
SpaceCover.Shape = SizedImage.Shape;
