import React from 'react';
import {Text} from 'react-native';
import {ApolloProvider} from '@apollo/client';
import Firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/analytics';
//import * as Analytics from 'expo-firebase-analytics';
import Color from '@hello10/color';
import {ApplicationContainer} from '../jump-client';

import {useTheme, config} from '@hello10/shared-client';
import logger from './logger';
import client from './client';
import Container from './Container';
import ApplicationLoading from './ApplicationLoading';
import useSession from './useSession';
import useRouter from './useRouter';

const arcs_firebase_config = config.manifest('extra.arcs.firebase');
const hello10_firebase_config = config.manifest('extra.hello10.firebase');

// Make Hello10 the default
// Shouldn't the default be arcs instead?
Firebase.initializeApp(hello10_firebase_config);
Firebase.initializeApp(hello10_firebase_config, 'hello10');
Firebase.initializeApp(arcs_firebase_config, 'arcs');

const analytics = Firebase.analytics();
analytics.logEvent('AppStarted', {
  app: 'Arcs'
});

// Analytics.logEvent('AppStarted', {
//   app: 'Arcs'
// });
// await Analytics.setCurrentScreen('GiveGithubStarsScreen');
// await Analytics.setUserId('my_user_uid');
// await Analytics.setUserProperty('favorite_batmobile', '1989 Burton-mobile');
// await Analytics.setUserProperties({
//   loves_expo: 'a lot',
// });

export default function Application () {
  const color = Color.fromName('KleinBlue');
  useTheme({state: {color}});

  logger.info('Rendering Application');
  return (
    <ApolloProvider client={client}>
      <ApplicationContainer
        Container={Container}
        ApplicationLoading={ApplicationLoading}
        PageLoading={ApplicationLoading}
        PageError={({error})=> (
          <Text>Error! {error?.message}</Text>
        )}
        client={client}
        useRouter={useRouter}
        useSession={useSession}
      />
    </ApolloProvider>
  );
}
