import React from 'react';
import {
  Text,
  TouchableOpacity
} from 'react-native';
import Styled from 'styled-components/native';

import {onPressRouteOrUrl} from '@hello10/shared-client';
import useRouter from './useRouter';

export function Link ({route, url, text, children, ...props}) {
  const router = useRouter();
  if (!(route || url)) {
    throw new Error('Requires route or url');
  }

  if (text && !children) {
    children = (
      <Text>{text}</Text>
    );
  }

  const onPress = onPressRouteOrUrl({url, route, router});

  return (
    <TouchableOpacity
      onPress={onPress}
      {...props}
    >
      {children}
    </TouchableOpacity>
  );
}
